import client from "./client";

const getProducts = (params) => {
    return client.get('/admin/product', { params })
        .then(({ data }) => data)
        .catch((err) => {
            return Promise.reject({ message: err?.message || 'Something went wrong...'});
        });
}

const getProductHistory = (productId, params) => {
    return client.get(`/admin/product/${productId}/history`, { params })
        .then(({ data }) => data)
        .catch((err) => {
            return Promise.reject({ message: err?.message || 'Something went wrong...'});
        });
}

const getOffers = (params) => {
    return client.get('/admin/product/offers', { params })
        .then(({ data }) => data)
        .catch((err) => {
            return Promise.reject({ message: err?.message || 'Something went wrong...'});
        });
}

export {
    getProducts,
    getProductHistory,
    getOffers
}