import Page from "../components/Page";
import {
    Alert,
    Box,
    Button,
    Card, Checkbox,
    Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel,
    ListItemIcon, ListItemText,
    MenuItem, Modal,
    Snackbar,
    Stack,
    Table, TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow, TextField,
    Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {UserListHead, UserMoreMenu} from "../sections/@dashboard/user";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

import Scrollbar from "../components/Scrollbar";
import {get2FACode, getSteamBots, updSteamBot, restartBot} from "../api";
import Label from "../components/Label";
import Iconify from "../components/Iconify";


const dateFormat = 'MM/DD/YYYY H:m';

const getIcon = (name) => <Iconify icon={name} width={25} height={25}/>;

const initialState2FA = {
    account: "",
    code2FA: ""
}
const initialStateEditing = {
    isActive: true,
    account: "",
    tradeUrl: "",
    proxiesId: []
}

const TABLE_HEAD = [
    {id: 'id', label: 'Id', alignRight: false},
    {id: 'createdAt', label: 'Created', alignRight: false},
    {id: 'isRunning', label: 'Running', alignRight: false},
    {id: 'isActive', label: 'Active', alignRight: false},
    {id: 'lastLoginTime', label: 'Last login time', alignRight: false},
    {id: 'lastLoginError', label: 'Last login error', alignRight: false},
    {id: 'account', label: 'Account', alignRight: false},
    {id: 'tradeUrl', label: 'Trade Url', alignRight: false},
    {id: 'currentCountInventory', label: 'Items in inventory', alignRight: false},
    {id: 'maxCountInventory', label: 'Max count inventory', alignRight: false},
    {id: '2fa', label: '2FA', alignRight: false},
    {id: 'edit', label: 'Edit', alignRight: false},
    {id: 'proxies', label: 'Proxy', alignRight: false},
    {id: 'action', label: '', alignRight: false},
];

const style = {
    textAlign: "center",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 120,
    bgcolor: 'background.paper',
    boxShadow: 10,
    p: 4,
};

const SteamBots = () => {

    const [order, setOrder] = useState('asc');
    const [steamBotsList, setSteamBotsList] = useState([]);
    const [open2FA, setOpen2FA] = useState(false);
    const [code2FA, setCode2FA] = useState(initialState2FA);
    const [openEdit, setOpenEdit] = useState(false);
    const [editBot, setEditBot] = useState(initialStateEditing);

    const [selectedBotId, setSelectedBotId] = useState(null);
    const [isRestartInProgress, setIsRestartInProgress] = useState(false);

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const getData = async () => {
            const {rows} = await getSteamBots();
            setSteamBotsList(rows);
        }
        getData();
    }, [])

    const handle2FAOpen = () => {
        setOpen2FA(true);
    }

    const handle2FAClose = () => {
        setOpen2FA(false);
        setCode2FA(initialState2FA)
    }

    const handleGet2FACode = async (row) => {
        const data = await get2FACode(row.id);
        setCode2FA({
            account: row.account,
            code2FA: data
        })
    }

    const handleEditOpen = (data) => {
        setOpenEdit(true);
        setEditBot(data)
    }

    const handleEditClose = () => {
        setOpenEdit(false);
        setEditBot(initialStateEditing)
    }

    const handleChangeEditing = (event) => {
        const { name, value } = event.target;
        setEditBot((prevState) => {
            return {
                ...prevState,
                [name]: !isNaN(value) ? +value : value,
            };
        });
    }

    const handleEditBot = async (params) => {
        const {success} = await updSteamBot(params);
        if (success) {

            setOpenEdit(false)
        }
    }

    const handleOpen = (id) => {
        setSelectedBotId(id);
    }

    const handleConfirmRestart = async () => {
        try {
            setIsRestartInProgress(true);
            await restartBot(selectedBotId);
            setSnackBarStatus('Success', false)
        } catch (error) {
            setSnackBarStatus(error.message, false)
        } finally {
            setIsRestartInProgress(false);
            setSelectedBotId(false);
        }
    }

    const setSnackBarStatus = (message, isError) => {
        setMessage(message);
        setIsError(isError);
        setShowSnackbar(true);
      };

    return (
        <Page title={"Steam bots"}>
            <Snackbar
                open={showSnackbar}
                message={message}
                severity={isError ? 'error' : 'success'}
                onClose={() => setShowSnackbar(false)}
                autoHideDuration={3000}
            >
                 <Alert onClose={() => setShowSnackbar(false)} severity={isError ? 'error' : 'success'} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Container maxWidth="xl" style={{filter: open2FA ? "blur(5px)" : "none"}}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Steam bots
                    </Typography>
                </Stack>

                <Card>
                    <TableContainer sx={{width: '100%', minWidth: 800}}>
                        <Scrollbar>
                            <Table>
                                <UserListHead
                                    order={order}
                                    headLabel={TABLE_HEAD}
                                    rowCount={3}
                                />
                                <TableBody>
                                    {steamBotsList?.map((row) => {
                                        const createdAt = dayjs(row.createdAt).format(dateFormat);
                                        const lastLoginTime = row.lastLoginTime ? dayjs(row.lastLoginTime * 1000).format(dateFormat) : '-';

                                        return (
                                            <TableRow
                                                hover
                                                key={row.id}
                                                tabIndex={-1}
                                                role="checkbox"
                                            >
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 140,
                                                    textOverflow: 'ellipsis'
                                                }} title={row.id}>{row.id}</TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 140,
                                                    textOverflow: 'ellipsis'
                                                }} title={createdAt}>{createdAt}</TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 140,
                                                    textOverflow: 'ellipsis'
                                                }} title={row.isRunning}>
                                                    <Label variant="ghost"
                                                           color={row.isRunning === false ? 'warning' : 'success'}>
                                                        {row.isRunning === false ? 'Not running' : 'Running'}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 140,
                                                    textOverflow: 'ellipsis'
                                                }} title={row.isActive}>
                                                    <Label variant="ghost"
                                                           color={row.isActive === false ? 'error' : 'success'}>
                                                        {row.isActive === false ? 'Stopped' : 'Active'}
                                                    </Label>
                                                </TableCell>

                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 140,
                                                    textOverflow: 'ellipsis'
                                                }} title={lastLoginTime}>
                                                    {lastLoginTime}
                                                </TableCell>

                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 140,
                                                    textOverflow: 'ellipsis'
                                                }} title={row.lastLoginError}>
                                                    {row.lastLoginError && (
                                                        <Label variant="ghost" color='error'>
                                                            {row.lastLoginError}
                                                        </Label>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 140,
                                                    textOverflow: 'ellipsis',
                                                }} title={row.account}>
                                                    <Button href={row.linkToInventory} target="_blank" rel="noreferrer">{row.account}</Button>
                                                </TableCell>
                                                <TableCell
                                                    onClick={() => navigator.clipboard.writeText(row.tradeUrl)}
                                                    align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 120,
                                                    textOverflow: 'ellipsis',
                                                    cursor: "copy"
                                                }}
                                                    title={row.tradeUrl}>
                                                    {row.tradeUrl}
                                                </TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 120,
                                                    textOverflow: 'ellipsis'
                                                }}
                                                           title={row.currentCountInventory}>{row.currentCountInventory}</TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 120,
                                                    textOverflow: 'ellipsis'
                                                }} title={row.maxCountInventory}>{row.maxCountInventory}</TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 120,
                                                    textOverflow: 'ellipsis'
                                                }} title={"2fa"}>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            handle2FAOpen();
                                                            handleGet2FACode(row);
                                                        }}
                                                    >
                                                        Show code
                                                    </Button>
                                                    <Modal
                                                        open={open2FA}
                                                        onClose={handle2FAClose}
                                                        sx={style}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box style={{position: "relative"}}>
                                                            <div
                                                                style={{
                                                                    position: "absolute",
                                                                    right: 0,
                                                                    top: 0,
                                                                    margin: "-15px -10px 0 0",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={handle2FAClose}>
                                                                {getIcon("material-symbols:close")}
                                                            </div>
                                                            <Typography id="modal-modal-title" variant="h6"
                                                                        component="h2">
                                                                Account: {code2FA.account}
                                                            </Typography>
                                                            <Typography
                                                                id="modal-modal-title"
                                                                variant="h6"
                                                                component="h2"
                                                                style={{cursor: "copy"}}
                                                                onClick={() => navigator.clipboard.writeText(code2FA.code2FA)}
                                                            >
                                                                2 FA code: {code2FA.code2FA}
                                                            </Typography>
                                                        </Box>
                                                    </Modal>
                                                </TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 120,
                                                    textOverflow: 'ellipsis'
                                                }} title={"edit"}>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => handleEditOpen(row)}
                                                    >
                                                        {getIcon("material-symbols:edit-document")}
                                                    </Button>

                                                    <Dialog open={openEdit} onClose={handleEditClose}>
                                                    <DialogTitle>Editing {editBot.account} account</DialogTitle>
                                                    <DialogContent>
                                                        <FormControlLabel control={<Checkbox onChange={(e) => setEditBot(prevState => {
                                                            return {
                                                                ...prevState,
                                                                isActive: !prevState.isActive
                                                            }
                                                        })} checked={editBot.isActive} />} label="Active" />
                                                    <TextField
                                                        onChange={handleChangeEditing}
                                                    autoFocus
                                                    value={editBot.account}
                                                    margin="dense"
                                                    id="name"
                                                    label="Account"
                                                    type="text"
                                                    name={"account"}
                                                    fullWidth
                                                    variant="standard"
                                                    />
                                                    <TextField
                                                        onChange={handleChangeEditing}
                                                    autoFocus
                                                    value={editBot.tradeUrl}
                                                    margin="dense"
                                                    id="name"
                                                    label="Trade Url"
                                                    type="text"
                                                    name={"tradeUrl"}
                                                    fullWidth
                                                    variant="standard"
                                                    />
                                                    <TextField
                                                        onChange={handleChangeEditing}
                                                    autoFocus
                                                    value={editBot.maxCountInventory}
                                                    margin="dense"
                                                    id="name"
                                                    label="Max count inventory"
                                                    type="nember"
                                                    name={"maxCountInventory"}
                                                    fullWidth
                                                    variant="standard"
                                                    />
                                                    </DialogContent>
                                                    <DialogActions>
                                                    <Button onClick={handleEditClose}>Cancel</Button>
                                                    <Button onClick={() => handleEditBot(editBot)}>Save</Button>
                                                    </DialogActions>
                                                    </Dialog>
                                                </TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 120,
                                                    textOverflow: 'ellipsis'
                                                }} title={"proxy"}>
                                                </TableCell>
                                                <TableCell align="left" style={{
                                                    overflow: 'hidden',
                                                    maxWidth: 120,
                                                    textOverflow: 'ellipsis'
                                                }} title={"action"}>
                                                    <Button onClick={() => handleOpen(row.id)}>Restart bot</Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {steamBotsList.length === 0 && (
                                        <TableRow style={{height: 250}}>
                                            <TableCell colSpan={6}> No Bots Was Found </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                </Card>
            </Container>
            <Dialog open={selectedBotId} onClose={() => setSelectedBotId(null)} style={{minWidth: '300px', minHeight: '150px', alignItems: 'center'}}>
                <DialogTitle sx={{textAlign: "center"}}>Confirm Restart</DialogTitle>
                <DialogContent  style={{minWidth: '300px', minHeight: '70px', alignItems: 'center', display: 'flex', justifyContent:'space-around'}}>
                    <Button onClick={() => setSelectedBotId(null)}>Cancel</Button>
                    <LoadingButton onClick={() => handleConfirmRestart(selectedBotId)} loading={isRestartInProgress}>Restart bot</LoadingButton>
                </DialogContent>
            </Dialog>
        </Page>
    )
}

export default SteamBots;