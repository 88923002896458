import Page from "../../components/Page";
import {
    Card,
    Container,
    MenuItem, Select,
    Stack,
    Table, TableBody,
    TableCell,
    TableContainer, TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import { UserListHead } from "../../sections/@dashboard/user";
import React, { useEffect, useState } from "react";

import { getStatistic, getBotAccounts } from "../../api"
import Scrollbar from "../../components/Scrollbar";
import { toDisplayFormat } from "../../utils/textTransform";

const TABLE_HEAD = [
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'steamBot', label: 'Steam Bot', alignRight: false },
    { id: 'endpoint', label: 'Endpoint', alignRight: false },
    { id: 'count', label: 'Count', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
];

const StatisticBots = () => {

    const [total, setTotal] = useState(0);
    const [statisticList, setStatisticList] = useState([])
    const [botAccountsList, setBotAccountsList] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [botFilter, setBotFilter] = useState('');

    const getBotAccountsList = () => {
        getBotAccounts().then((list) => {
            const accounts = list?.accounts?.sort((a, b) => {
                if(a < b) return -1;
                if(a > b) return 1;
                return 0;
            });

            setBotAccountsList(accounts || []);
            setBotFilter(accounts?.[0] || '')
        })
    }

    const getStatisticList = ({ limit, offset, bot }) => {
        if (!bot && !botFilter) return;

        const getData =  async () => {
            const params = {
                limit: limit || rowsPerPage,
                offset: offset || page * rowsPerPage,
                account: bot || botFilter,
            };

            const {data, total} = await getStatistic(params);
            setStatisticList(data);
            setTotal(total)
        }
        getData();
    };

    useEffect(() => {
        getBotAccountsList();
    }, []);

    useEffect(() => {
        getStatisticList({});
    }, [page, rowsPerPage, botFilter]);

    const handleChangePage = async (event, currentPage) => {
        setPage(currentPage);
    }

    const handleChangeRowsPerPage = async (event) => {
        const itemsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(itemsPerPage);
        setPage(0);
    }

    const botOnChange = (e) => {
        setBotFilter(e.target.value);
        setPage(0);
    };

    return (
        <Page title={"Statistic"}>
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Statistic
                    </Typography>
                </Stack>

                <Stack direction="row" mb={5}>
                    <Container direction="row" sx={{ ml: 0 }}>
                        <Select onChange={botOnChange} value={botFilter} defaultValue="" sx={{ width: '300px', ml: '25px' }}>
                            {
                                botAccountsList?.map((account) => (
                                    <MenuItem key={account} value={account}>
                                        {toDisplayFormat(account)}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </Container>
                </Stack>

                <Card>
                    <TableContainer sx={{ width: '100%', minWidth: 800 }}>
                        <Scrollbar>
                            <Table>
                            <UserListHead
                                order="asc"
                                headLabel={TABLE_HEAD}
                                rowCount={4}
                            />
                            <TableBody>
                                {statisticList?.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            key={row.id}
                                            tabIndex={-1}
                                            role="checkbox"
                                        >
                                            <TableCell align="left" noWrap style={{overflow: 'hidden', maxWidth: 100, textOverflow: 'ellipsis'}} title={row.id}>{row.id}</TableCell>
                                            <TableCell align="left" noWrap style={{overflow: 'hidden', maxWidth: 100, textOverflow: 'ellipsis'}} title={row.steamBot.account}>{row.steamBot.account} (id:{row.steamBotId})</TableCell>
                                            <TableCell align="left" noWrap style={{overflow: 'hidden', maxWidth: 100, textOverflow: 'ellipsis'}} title={row.endpoint}>{row.endpoint}</TableCell>
                                            <TableCell align="left" style={{overflow: 'hidden', maxWidth: 100, textOverflow: 'ellipsis'}} title={row.count}>{row.count}</TableCell>
                                            <TableCell align="left" noWrap style={{overflow: 'hidden', maxWidth: 100, textOverflow: 'ellipsis'}} title={row.date}>{row.date}</TableCell>
                                        </TableRow>
                                    );
                                })}
                                {statisticList.length === 0 && (
                                    <TableRow style={{ height: 250 }}>
                                        <TableCell colSpan={6}> No Statistic Was Found </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </Page>
    )
}

export default StatisticBots;