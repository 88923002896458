import React from "react";
import { sentenceCase } from "change-case";

import Label from "../components/Label";

import { OrderStatus, OrderContractStatus } from '../constants';

export function OrderStatusLabel(status) {
    const getColor = () => {
        switch (status) {
            case OrderStatus.CANCELLED:
            case OrderStatus.DECLINED:
            case OrderStatus.CANCELLED_ITEMS_WAS_NOT_SENT:
            case OrderStatus.PAYMENT_INVALID:
            case OrderStatus.PAYMENT_EXPIRED:
            case OrderContractStatus.REFUNDED:
                return 'error';

            case OrderStatus.AWAITING_PAYMENT:
            case OrderStatus.AWAITING_TRADE_CONFIRMATION:
            case OrderStatus.AWAITING_TRANSFER_ITEM:
            case OrderStatus.PAID:
            case OrderStatus.CONFIRMED:
                return 'warning';

            case OrderStatus.COMPLETED:
            case OrderContractStatus.PENDING:
            case OrderContractStatus.WITHDRAWN:
            case OrderContractStatus.WITHDRAWN_FEE:
                return 'success';

            default:
                return 'default';
        }
    }


    return (
        <Label variant="ghost" color={getColor()}>
            {sentenceCase(status)}
        </Label>
    );
}