// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Orders',
    path: '/dashboard/orders',
    icon: getIcon('eva:shopping-cart-fill'),
  },
  {
    title: 'Products',
    path: '/dashboard/products',
    icon: getIcon('dashicons:products'),
  },
  {
    title: 'Homepage edits',
    path: '/dashboard/homepage_edits',
    icon: getIcon('dashicons:products'),
  },
  {
    title: 'Packs',
    path: '/dashboard/packs',
    icon: getIcon('mdi:package-variant-closed'),
  },
  {
    title: 'Bot statistics',
    path: '/dashboard/statistic/bots',
    icon: getIcon('wpf:statistics'),
  },
  {
    title: 'Market statistics',
    path: '/dashboard/statistic/market',
    icon: getIcon('akar-icons:statistic-up'),
  },
  {
    title: 'steam_bots',
    path: '/dashboard/steam_bots',
    icon: getIcon('fluent:bot-24-filled'),
  },
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: getIcon('eva:settings-fill'),
  },
  {
    title: 'FAQ',
    path: '/dashboard/faq',
    icon: getIcon('eva:question-mark-circle-fill'),
  },
];

export default navConfig;
