import client from './client';

const login = (values) => client.post('/auth/system-login', values)
      .then(({ data }) => {
        client.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
        return data;
      })
      .catch((error) => Promise.reject(error));

const confirmLogin = (values) => client.post('/auth/system-login/confirm', values)
      .then(({ data }) => {
        client.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
        return data;
      })
      .catch((error) => Promise.reject(error));

const tryRestoreUserSession = async () => {
  return new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem('admin-token');
    const parsedToken = accessToken && JSON.parse(accessToken).value;

    if(!parsedToken) {
        localStorage.removeItem('krakatoa-user-admin');
        reject();
    }

    client.defaults.headers.common.Authorization = `Bearer ${parsedToken}`;
    resolve();
  })
}


export {
  login,
  tryRestoreUserSession,
  confirmLogin,
};
