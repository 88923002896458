export const OrderStatus = {
    NEW: 'new',
    AWAITING_PAYMENT: 'awaiting_payment',
    PAYMENT_EXPIRED: 'payment_expired',
    PAYMENT_INVALID: 'payment_invalid',
    PAID: 'paid',
    CONFIRMED: 'confirmed',
    COMPLETED: 'completed',
    DECLINED: 'declined',
    CANCELLED: 'cancelled',
    CANCELLED_ITEMS_WAS_NOT_SENT: 'cancelled_items_was_not_sent',
    AWAITING_TRANSFER_ITEM: 'awaiting_transfer_item',
    AWAITING_TRADE_CONFIRMATION: 'awaiting_trade_confirmation',
}

export const OrderContractStatus = {
    UNKNOWN: 'Unknown',
    PENDING: 'Pending',
    WITHDRAWN_FEE: 'WithdrawnFee',
    WITHDRAWN: 'Withdrawn',
    REFUNDED: 'Refunded',
}

export const OrderFilter = {
    COMPLETED_NOT_WITHDRAWN: 'completed-not-withdrawn',
    COMPLETED_FEE_WITHDRAWN: 'completed-fee-withdrawn',
    COMPLETED_ALL_FUNDS_WITHDRAWN: 'completed-all-fund-withdrawn',
    ACTIVE_PROCESSING_ORDERS: 'active-processing-orders',
    FAILED_ITEM_WAS_NOT_SENT: 'failed-item-was-not-sent',
    FAILED_OTHER_REASON: 'failed-other-reason',
}

export const ProductFilter = {
    PENDING_OFFER: 'pending_offer', // offer was created, item is not a krakatoa acc yet
    INVALID: 'invalid',
    EXPIRED: 'expired', // offer expired on steam
    IN_ESCROW: 'in_escrow', // the trade has been placed on hold
    CANCEL: 'cancel', // offer was cancelled by user
    DECLINED: 'declined', // offer was declined by user
    HOLD: 'hold', // item is on krakatoa account, but visible only for user which added it
    PROCESSING: 'processing', // checkout is in progress, product is hidden for other users
    LISTED: 'listed', // item is on krakatoa account and allowed for trading
    WITHDRAWING: 'withdrawing', // bot created an offer to transfer item from krakatoa to user account
    WITHDRAWN: 'withdrawn', // product was withdrawn to user account
}
