import { Stack } from "@mui/material";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FormProvider, RHFTextField} from "../../../components/hook-form";
import { RHFSelectCategories } from "../../../components/hook-form/RHFSelectField";
import { createQuestion } from "../../../api/faq";
import { useNavigate } from "react-router-dom";

const FaqCreateQuestionForm = ({ categories, setLoader }) => {
    const navigate = useNavigate();

    const onSubmit = (value) => {
        setLoader(true)
        createQuestion(value)
            .then(() => navigate('/dashboard/faq'))
            .catch((err) => console.log(err))
            .finally(() => setLoader(false))
    }

    const FaqSchema = Yup.object().shape({
        categoryId: Yup.number().required('Category is required'),
        title: Yup.string().required('Question is required'),
        answer: Yup.string().required('Answer is required'),
    });

    const methods = useForm({
        resolver: yupResolver(FaqSchema),
        defaultValues: { title: '', categoryId: 0, answer: '' },
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    return (
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3} sx={{ my: 2 }}>
                    <RHFSelectCategories name="categoryId" label="Category" placeholder="Enter category" categories={categories}/>
                    <RHFTextField name="title" label="Title" placeholder="Enter question" />
                    <RHFTextField name="answer" label="Answer" placeholder="Enter answer" />
                </Stack>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Create Question
                </LoadingButton>
            </FormProvider>
    )
}

export default FaqCreateQuestionForm;