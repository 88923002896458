import client from './client';

const getPackDetails = async (alias) => {
  return client
    .get(`/pack/${alias}?withFilters=true`)
    .then(({ data }) => data)
    .catch((err) => {
      return Promise.reject({ message: err?.message || 'Something went wrong...' });
    });
};

const getPacks = async (limit, offset) => {
  return client
    .get(`/pack?limit=${limit}&offset=${offset}`)
    .then(({ data }) => data)
    .catch((err) => {
      return Promise.reject({ message: err?.message || 'Something went wrong...' });
    });
};


const postPack = async (formData) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return client
    .post('/system-pack', formData, { headers })
    .then(({ data }) => data)
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

const updatePack = async (packId, formData) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  return client
    .put(`/system-pack/${packId}`, formData, { headers })
    .then(({ data }) => data)
    .catch((err) => {
      return Promise.reject({ message: err?.message || 'Something went wrong...' });
    });
};

const checkProductsByFilters = async (appId, data) => {
  return client
    .post(`/system-pack/products/${appId}`, data)
    .then(({ data }) => data)
    .catch((err) => {
      return Promise.reject({ message: err?.message || 'Something went wrong...' });
    });
};

const removePack = async (packId) => {
  return client
    .delete(`/system-pack/${packId}`)
    .then(({ data }) => data)
    .catch((err) => {
      return Promise.reject({ message: err?.message || 'Something went wrong...' });
    });
};

const groupAutocomplete = async (searchQuery) => {
  return client
    .get(`search/suggests/1?search=${searchQuery}`)
    .then(({ data }) => data)
    .catch((err) => {
      return Promise.reject({ message: err?.message || 'Something went wrong...' });
    });
};

const getGroupDetails = async (groupId) => {
  return client
    .get(`group/${groupId}/details`)
    .then(({ data }) => data)
    .catch((err) => {
      return Promise.reject({ message: err?.message || 'Something went wrong...' });
    });
};

export {
  getPackDetails,
  getPacks,
  postPack,
  updatePack,
  checkProductsByFilters,
  removePack,
  groupAutocomplete,
  getGroupDetails,
};
