import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import {
    Card,
    Stack,
    Container,
    Typography,
    TablePagination,
    Snackbar,
    Alert,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    CircularProgress,
} from '@mui/material';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { useAuth } from '../../contexts/AuthProvider';
import { getFAQs } from "../../api";
import { UserListHead } from "../../sections/@dashboard/user";
import Iconify from "../../components/Iconify";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'id', alignRight: false },
    { id: 'category', label: 'Category', alignRight: false },
    { id: 'title', label: 'Title', alignRight: false },
    { id: 'answer', label: 'Answer', alignRight: false },
];

// ----------------------------------------------------------------------

export default function FAQ() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [page, setPage] = useState(0);

    const [loader, setLoader] = useState(true);

    const [message, setMessage] = useState('');

    const [showSnackbar, setShowSnackbar] = useState(false);

    const [isError, setIsError] = useState(false);

    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [faqList, setFaqList] = useState([]);

    const [totalFAQs, setTotalFAQs] = useState(0);

    if(!user) return <Navigate to="/login" />;

    useEffect(() => {
        getFaqList();
    }, [])

    const setSnackbarStatus = (status) => {
        setShowSnackbar(status);
    }

    const getFaqList = (query) => {
        query = {
            offset: query?.offset || page * rowsPerPage,
            limit: query?.limit || rowsPerPage,
        }

        setLoader(true);
        getFAQs(query)
            .then((data) => {
                setFaqList(data?.data);
                setTotalFAQs(data?.total);
            })
            .catch((err) => {
                setMessage(err.message || 'Something went wrong...');
                setIsError(true);
                console.error(err);
            })
            .finally(() => setLoader(false));
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getFaqList({ offset: newPage * rowsPerPage });
    };

    const handleChangeRowsPerPage = (event) => {
        const itemsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(itemsPerPage);
        setPage(0);
        getFaqList({ offset: 0, limit: itemsPerPage });
    };

    const emptyRows = faqList?.length || 0;

    return (
        <Page title="FAQ">
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography width={100} display="flex" variant="h4" gutterBottom>
                        FAQ
                        <Iconify
                            onClick={() => navigate('/dashboard/faq/create') }
                            icon="eva:plus-circle-outline"
                            width={28}
                            height={28}
                            marginTop="5px"
                            marginLeft="5px"
                            cursor="pointer"
                        />
                    </Typography>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={showSnackbar}
                        onClose={() => setSnackbarStatus(false)}
                        autoHideDuration={5000}
                    >
                        <Alert onClose={() => setSnackbarStatus(false)} severity={isError ? "error" : "success" } sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                </Stack>

                {
                    loader
                        ? (
                            <Container>
                                <Stack direction="row" alignItems="center" justifyContent="center" mb={5} style={{height: '30vh'}}>
                                    <CircularProgress />
                                </Stack>
                            </Container>
                        )
                        : (
                            <Card>
                                <Scrollbar>
                                    <TableContainer sx={{ width: '100%', minWidth: 800 }}>
                                        <Table>
                                            <UserListHead
                                                order="asc"
                                                headLabel={TABLE_HEAD}
                                                rowCount={faqList?.length}
                                            />
                                            <TableBody>
                                                {faqList?.map((row) => {
                                                    const { id, title, answer, category } = row;

                                                    return (
                                                        <TableRow
                                                            hover
                                                            key={id}
                                                            tabIndex={-1}
                                                            role="checkbox"
                                                        >
                                                            <TableCell component="th" scope="row" padding="none" style={{ paddingLeft: '14px', paddingRight: '14px', width: '6%' }}>
                                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                                    <Typography variant="subtitle2">
                                                                        {id}
                                                                    </Typography>
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="left" style={{overflow: 'hidden', width: '10%', textOverflow: 'ellipsis'}} title={category.name}>{category.name}</TableCell>
                                                            <TableCell align="left" style={{overflow: 'hidden', width: '42%', textOverflow: 'ellipsis'}} title={title}>{title}</TableCell>
                                                            <TableCell align="left" style={{overflow: 'hidden', width: '42%', textOverflow: 'ellipsis'}} title={answer}>{answer}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                                {
                                                    emptyRows
                                                        ? null
                                                        : (
                                                            <TableRow style={{ height: 250 }}>
                                                                <TableCell colSpan={4}> No FAQ Was Found </TableCell>
                                                            </TableRow>
                                                        )
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                                    component="div"
                                    count={totalFAQs || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Card>
                        )
                }
            </Container>
        </Page>
    );
}