import client from './client';
const CONTRACT_FEE_COEFFICIENT = 100;

const getFee = () => client.get('/settings/fee')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err))

const getFeeChangeSignature = async (precent) => {
    return client.get(`/settings/change-fee-request?fee=${precent * CONTRACT_FEE_COEFFICIENT}`)
        .catch((err) => Promise.reject(err))
}

const updateFee = async (precent) => {
    return client.put(`/settings/change-fee`, {fee: precent})
        .catch((err) => Promise.reject(err))
}


export {
    getFee,
    getFeeChangeSignature,
    updateFee
};
