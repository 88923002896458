import {
  Card,
  Typography,
  Snackbar,
  Stack,
  Container,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  Modal,
  TablePagination,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getPacks, removePack } from 'src/api/packs';
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { UserListHead } from 'src/sections/@dashboard/user';
import Details from './Details';

import styles from './Packs.module.scss';

const REACT_APP_PUBLIC_APP_URL = process.env.REACT_APP_PUBLIC_APP_URL;

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'contentImage', label: 'Content Image', alignRight: false },
  { id: 'backgroundImage', label: 'Background Image', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'section', label: 'Section', alignRight: false },
  { id: 'action', label: '', alignRight: false },
];

const Packs = () => {
  const [packs, setPacks] = useState([]);
  const [totalPacks, setTotalPacks] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const [selectedPackAlias, setSelectedPackAlias] = useState(null);
  const [snackSettings, setSnackSettings] = useState({ isVisible: false, message: '', isError: false });

  const fetchPacks = async (page, limit) => {
    try {
      const offset = limit * page;
      const { data, total } = await getPacks(limit, offset);
      setTotalPacks(total);
      setPacks(data);
    } catch (error) {
      console.error(error);
      setSnackSettings({ isVisible: true, message: error.message, isError: true });
    }
  };

  useEffect(() => {
    fetchPacks(page, limit);
  }, [page, limit]);

  useEffect(() => {
    if (!isDetailsVisible) {
      setSelectedPackAlias(null);
    }
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setLimit(Number(event.target.value));
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const showPackDetails = (alias) => {
    setSelectedPackAlias(alias);
    setIsDetailsVisible(true);
  };

  const removePackHandler = async (packId) => {
    try {
      await removePack(packId);
      setPacks((prev) => prev.filter((packs) => packs?.id !== packId));
    } catch (error) {
      setSnackSettings({ isVisible: true, message: error.message, isError: true });
    }
  };

  console.log('packs', packs);

  return (
    <Page title={'Products'}>
      <Container maxWidth="xl">
        <Stack direction="row" mb={2} sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h4" gutterBottom>
            Packs
          </Typography>
          <Snackbar
            open={snackSettings.isVisible}
            message={snackSettings.message}
            severity={snackSettings.isError && 'error'}
            onClose={() => setSnackSettings((prev) => ({ isVisible: false, message: '', isError: false }))}
            autoHideDuration={3000}
          />
          <Button
            onClick={() => {
              setIsDetailsVisible(true);
            }}
          >
            Add new pack
          </Button>
        </Stack>
        <Card>
          <TableContainer sx={{ width: '100%' }}>
            <Scrollbar>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={packs?.length} />
                <TableBody>
                  {packs.map((pack) => (
                    <TableRow key={pack?.id} hover tabIndex={-1} role="checkbox">
                      <TableCell
                        align="left"
                        style={{
                          overflow: 'hidden',
                          maxWidth: 50,
                          minWidth: 50,
                          textOverflow: 'ellipsis',
                        }}
                        title={'id'}
                      >
                        {pack?.id}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          overflow: 'hidden',
                          maxWidth: 140,
                          minWidth: 100,
                          textOverflow: 'ellipsis',
                        }}
                        title={'title'}
                      >
                        <a
                          href={`${REACT_APP_PUBLIC_APP_URL}/pack/${pack?.alias}`}
                          target="_blank"
                          className={styles.link}
                        >
                          {pack?.name}
                        </a>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          overflow: 'hidden',
                          maxWidth: 140,
                          minWidth: 100,
                          textOverflow: 'ellipsis',
                        }}
                        title={'image'}
                      >
                        <img
                          src={
                            typeof pack?.contentImageUrl === 'string'
                              ? pack?.contentImageUrl
                              : typeof pack?.contentImageUrl === 'object'
                              ? URL.createObjectURL(pack?.contentImageUrl)
                              : ''
                          }
                          alt={`pack content image, updated at ${pack?.updatedAt}`}
                          width={50}
                          height={50}
                          className={styles.packImg}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          overflow: 'hidden',
                          maxWidth: 140,
                          minWidth: 100,
                          textOverflow: 'ellipsis',
                        }}
                        title={'image'}
                      >
                        <img
                          src={
                            typeof pack?.backgroundImageUrl === 'string'
                              ? pack?.backgroundImageUrl
                              : typeof pack?.backgroundImageUrl === 'object'
                              ? URL.createObjectURL(pack?.backgroundImageUrl)
                              : ''
                          }
                          alt={`pack backgroud image, updated at ${pack?.updatedAt}`}
                          width={50}
                          height={50}
                          className={styles.packImg}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          overflow: 'hidden',
                          maxWidth: 140,
                          minWidth: 100,
                          textOverflow: 'ellipsis',
                        }}
                        title={'description'}
                      >
                        <Tooltip title={pack?.description} placement="top" arrow>
                          <span>
                            {pack?.description.slice(0, 20)}
                            {pack?.description.length > 20 ? '...' : ''}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          overflow: 'hidden',
                          maxWidth: 140,
                          minWidth: 100,
                          textOverflow: 'ellipsis',
                          textTransform: 'capitalize'
                        }}
                        title={'Section'}
                      >
                        {pack?.section?.replaceAll('-', ' ')}
                      </TableCell>
                      <TableCell align="right" title={'actions'}>
                        <div className={styles.actionBtns}>
                          <Button onClick={() => showPackDetails(pack?.alias)}>Details</Button>
                          <Button variant="outlined" color="error" onClick={() => removePackHandler(pack?.id)}>
                            Delete Pack
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={totalPacks}
                rowsPerPage={limit}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Scrollbar>
          </TableContainer>
        </Card>
      </Container>
      <Details
        isVisible={isDetailsVisible}
        onClose={() => {
          setIsDetailsVisible(false);
          setSelectedPackAlias(null);
        }}
        selectedPackAlias={selectedPackAlias}
        onUpdate={(updatedPack) => {
          setPacks((prev) => [...prev.map((pack) => (pack?.id === updatedPack?.id ? updatedPack : pack))]);
          setIsDetailsVisible(false);
          setSelectedPackAlias(null);
        }}
        onSubmit={(newPack) => {
          setPacks((prev) => [...prev, newPack]);
          setIsDetailsVisible(false);
          setSelectedPackAlias(null);
        }}
      />
    </Page>
  );
};

export default Packs;
