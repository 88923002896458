import client from "./client";

const getSteamBots = () => {
    return client.get('/steam-bot')
        .then(({data}) => data)
        .catch(err => {
            const message = err.message;
            const error = { message };
            return Promise.reject(error);
        });
}

const get2FACode = (id) => {
    return client.get(`/steam-bot/bot/${id}/totp`)
        .then(({data}) => data)
        .catch(err => {
            const message = err.message;
            const error = { message };
            return Promise.reject(error);
        });
}

const updSteamBot = (params) => {
    const editedBotData = {
        isActive: params.isActive,
        account: params.account,
        password: params.password,
        apiKey: params.apiKey,
        sharedSecret: params.sharedSecret,
        identifySecret: params.identifySecret,
        tradeUrl: params.tradeUrl,
        microServiceHost: params.microServiceHost,
        microServicePort: params.microServicePort,
        maxCountInventory: params.maxCountInventory,
        proxiesId: []
    }

    return client.put(`/steam-bot/bot/${params.id}`, editedBotData)
        .then(({data}) => data)
        .catch(err => {
            const message = err.message;
            const error = { message };
            return Promise.reject(error);
        });
}

const restartBot = async (id) => {
    return client.put(`/steam-bot/bot/${id}/restart`)
    .then(({data}) => data)
    .catch(err => {
        const message = err.message;
        const error = { message };
        return Promise.reject(error);
    });
}

export {getSteamBots, get2FACode, updSteamBot, restartBot };