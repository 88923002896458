import { Controller, useFormContext} from "react-hook-form";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";


export function RHFSelectCategories({ categories, value, name, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <FormControl>
                    <InputLabel id="faq-create-categories">Category</InputLabel>
                    <Select
                        {...field}
                        fullWidth
                        labelId={"faq-create-categories"}
                        value={field.value || ''}
                        error={!!error}
                        {...other}
                    >
                        {
                            categories?.map((category) => <MenuItem name={category.id} key={category.id} value={category.id}>{category.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
        )}
        />
    );
}