import {useEffect, useState} from "react";
import {CircularProgress, Container, Stack} from "@mui/material";
import { useNavigate } from "react-router-dom";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { AuthProvider } from './contexts';
// components
import ScrollToTop from './components/ScrollToTop';
import {tryRestoreUserSession} from "./api";

// ----------------------------------------------------------------------

export default function App() {
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        tryRestoreUserSession()
            .catch(() => {
                navigate('/login')
            })
            .finally(() => {
                setLoader(false)
            })
    }, []);

  return (
    <ThemeProvider>
        {loader ? (
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="center" mb={5} style={{height: '100vh'}}>
                    <CircularProgress />
                </Stack>
            </Container>
        ) : (
        <AuthProvider>
            <ScrollToTop />
            <Router />
        </AuthProvider>
        )}
    </ThemeProvider>
  );
}
