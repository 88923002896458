import axios from 'axios';

let baseURL = process.env.REACT_APP_PUBLIC_API_URL;

const client = axios.create({ baseURL });

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      console.log('401');
      localStorage.removeItem('krakatoa-user-admin');
      localStorage.removeItem('admin-token');

      delete client.defaults.headers.common.Authorization;

      const { origin } = window.location;
      window.location.replace(`${origin}/login`);
    }
    return Promise.reject(error);
  },
)


export default client;
