import Page from "../../components/Page";
import {Box, Container, Paper, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {getMarketStatistics} from "../../api";

const paperStyle = {
    flexGrow: '1',
    margin: '15px 20px',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '144px',
    height: '192px',
    textAlign: 'center',
    '&:hover': {
        outlineWidth: '1px',
        outlineStyle: 'solid',
        outlineColor: 'rgba(223, 227, 232, .3)',
    }
}

const typographyStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
}

export function StatisticMarket() {

    const [statistics, setStatistics] = useState({});

    useEffect(() => {
        getMarketStatistics().then((data) => setStatistics(data));
    }, []);

    return (
      <Page title = 'Market statistics'>
          <Container maxWidth="xl" direction="row">
              <Stack direction="row" mb={2}>
                  <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'space-evenly',
                    }}
                  >
                      {
                          Object.entries(statistics).length
                              ? Object.entries(statistics).map(([key, value]) => (
                                  <Paper elevation={2} sx={paperStyle} key={key}>
                                      <Typography sx={typographyStyle}>{value.alias || "undefined"}</Typography>
                                      <p>Total: {value.allTime}</p>
                                      <p>Today: {value.today}</p>
                                      <p>Yesterday: {value.yesterday}</p>
                                      <p>Last week: {value.lastWeek}</p>
                                  </Paper>
                              ))
                              : <h2>Empty</h2>
                      }
                  </Box>
              </Stack>
          </Container>
      </Page>
    );
}