import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import {
    Alert,
    Card,
    CircularProgress,
    Container,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import Iconify from "../../components/Iconify";
import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthProvider";
import Scrollbar from "../../components/Scrollbar";
import { UserListHead } from "../../sections/@dashboard/user";
import { getProducts } from "../../api/orders";


const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'userId', label: 'User ID', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false },
    { id: 'checkoutType', label: 'Checkout Type', alignRight: false },
]

export function OrdersProducts() {
    const { user } = useAuth();

    const navigate = useNavigate();
    const { id: orderId } = useParams();

    if (!user) {
        navigate('/login');
    }

    if (!orderId) {
        navigate('/');
    }

    // Api Request
    const [loader, setLoader] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Api Response
    const [products, setProducts] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);

    // Snackbar
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState('');


    const requestProducts = (query) => {
        query = {
            offset: query?.offset || page * rowsPerPage,
            limit: query?.limit || rowsPerPage,
        }

        setLoader(true);
        getProducts(orderId, query)
            .then((data) => {
                setProducts(data?.data || []);
                setTotalProducts(data?.total || 0);
            })
            .catch((error) => {
                setMessage(err.message || 'Something went wrong...');
                setIsError(true);
                console.error(error);
            })
            .finally(() => setLoader(false));
    }

    // Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        requestProducts({ offset: newPage * rowsPerPage });
    }

    const handleChangeRowsPerPage = (event) => {
        const itemsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(itemsPerPage);
        setPage(0);
        requestProducts({ offset: 0, limit: itemsPerPage });
    }

    useEffect(() => {
        requestProducts();
    }, [page, rowsPerPage])

    return (
        <Page title="Orders' products">
            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" mb={5}>
                    <Iconify
                        onClick={() => navigate('/dashboard/orders')}
                        icon="eva:arrow-back-fill"
                        width={30}
                        height={30}
                        cursor="pointer"
                    />
                    <Typography width={200} display="flex" ml={1} variant="h4" alignItems="center" gutterBottom>
                        Orders' Products
                    </Typography>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={showSnackbar}
                        onClose={() => setShowSnackbar(false)}
                        autoHideDuration={5000}
                    >
                        <Alert onClose={() => setShowSnackbar(false)} severity={isError ? "error" : "success" } sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                </Stack>

                {
                    loader
                        ? (
                            <Container>
                                <Stack direction="row" alignItems="center" justifyContent="center" mb={5} style={{ height: '30vh' }}>
                                    <CircularProgress/>
                                </Stack>
                            </Container>
                        )
                        : (
                            <Card>
                                <Scrollbar>
                                    <TableContainer>
                                        <Table>
                                            <UserListHead
                                                order="asc"
                                                headLabel={TABLE_HEAD}
                                                rowCount={products?.length}
                                            />
                                            <TableBody>
                                                {
                                                    products?.map((product) => {
                                                        const { id, userId, status, price, checkoutType, marketHashName: name } = product;

                                                        return (
                                                            <TableRow
                                                                hover
                                                                key={id}
                                                                tabIndex={-1}
                                                                role="checkbox"
                                                            >
                                                                <TableCell component="th" scope="row" padding="none" style={{ paddingLeft: '14px', paddingRight: '14px', width: '6%'}}>
                                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                                        <Typography variant="subtitle2">
                                                                            {id}
                                                                        </Typography>
                                                                    </Stack>
                                                                </TableCell>
                                                                <TableCell align="left" style={{ overflow: 'hidden', width: '10%', textOverflow: 'ellipsis'}} title={name}>{name}</TableCell>
                                                                <TableCell align="left" style={{ overflow: 'hidden', width: '10%', textOverflow: 'ellipsis'}} title={userId}>{userId}</TableCell>
                                                                <TableCell align="left" style={{ overflow: 'hidden', width: '10%', textOverflow: 'ellipsis'}} title={status}>{status}</TableCell>
                                                                <TableCell align="left" style={{ overflow: 'hidden', width: '10%', textOverflow: 'ellipsis'}} title={price}>{price}</TableCell>
                                                                <TableCell align="left" style={{ overflow: 'hidden', width: '10%', textOverflow: 'ellipsis'}} title={checkoutType}>{checkoutType}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                }
                                                {
                                                    products?.length
                                                        ? null
                                                        : (
                                                            <TableRow style={{ height: 250 }}>
                                                                <TableCell colSpan={5}>No products was found</TableCell>
                                                            </TableRow>
                                                        )
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>

                                <TablePagination
                                    rowsPerPageOptions={[5,10,20,25,50,100]}
                                    component="div"
                                    count={totalProducts || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />

                            </Card>
                        )
                }

            </Container>
        </Page>
    );
}