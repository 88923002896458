import {Box, Modal, Table, TableBody, TableCell, TablePagination, TableRow, Typography} from "@mui/material";
import {UserListHead} from "../../sections/@dashboard/user";
import {formatHash} from "../../utils/formatHash";
import React, {useEffect, useState} from "react";
import {getProductHistory} from "../../api";
import Iconify from "../../components/Iconify";


const MODAL_STYLE = {
    textAlign: 'center',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 1050,
    maxHeight: '90vh',
    minHeight: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 10,
    p: 4,
};

const initialStateProductHistory = {
    product: '',
    rows: [],
};

const TABLE_PRODUCT_HISTORY_HEAD = [
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'publicAddress', label: 'Public Address', alignRight: false },
    { id: 'steamBotId', label: 'SteamBotId', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
    { id: 'checkoutType', label: 'CheckoutType', alignRight: false },
    { id: 'assetId', label: 'AssetId', alignRight: false },
    { id: 'offerId', label: 'OfferId', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false },
    { id: 'result', label: 'Result', alignRight: false },
];


const getIcon = (name) => <Iconify icon={name} width={25} height={25} />;

export function ProductHistoryModal(props) {
    const {openModal, setOpenModal, currentProduct, setCurrentProduct, setSnackBarStatus, setLoader} = props;

    const [productHistory, setProductHistory] = useState(initialStateProductHistory);
    const [totalProductHistory, setTotalProductHistory] = useState(null);
    const [rowsPerPageHistory, setRowsPerPageHistory] = useState(10);
    const [pageHistory, setPageHistory] = useState(0);

    const handleChangePageHistory = (_, newPage) => {
        setPageHistory(newPage);
    };
    const handleChangeRowsPerPageHistory = (event) => {
        const itemsPerPage = +event.target.value;

        setRowsPerPageHistory(itemsPerPage);
        setPageHistory(0);
    };

    const handleProductHistoryClose = () => {
        setOpenModal(false);
        setCurrentProduct(null);
        setPageHistory(0);
        setProductHistory(initialStateProductHistory);
    };

    const handleGetProductHistory = async () => {
        const params = {
            limit: rowsPerPageHistory,
            offset: pageHistory * rowsPerPageHistory,
        };

        getProductHistory(currentProduct.id, params)
            .then(({ data, total }) => {
                setProductHistory({
                    productId: currentProduct.id,
                    productName: currentProduct.name,
                    rows: data,
                });
                setTotalProductHistory(total);
            })
            .catch((err) => setSnackBarStatus(err.message, true))
            .finally(() => setLoader(false));
    };

    useEffect(() => {
        if (currentProduct && openModal) {
            handleGetProductHistory();
        }
    }, [currentProduct, pageHistory, rowsPerPageHistory]);

    return (
        <Modal
            open={openModal}
            onClose={handleProductHistoryClose}
            sx={MODAL_STYLE}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <div
                    style={{
                        position: 'absolute',
                        right: 15,
                        top: 15,
                        cursor: 'pointer',
                    }}
                    onClick={handleProductHistoryClose}
                >
                    {getIcon('material-symbols:close')}
                </div>
                <Box style={{
                    position: 'relative',
                    height: '100%',
                    overflow: 'auto',
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Product: {currentProduct?.id}
                    </Typography>

                    <Table>
                        <UserListHead headLabel={TABLE_PRODUCT_HISTORY_HEAD} rowCount={productHistory?.rows?.length} />
                        <TableBody>
                            {productHistory?.rows?.map((historyRow) => {
                                return (
                                    <TableRow hover key={historyRow.id} tabIndex={-1} role="checkbox">
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={historyRow.id}
                                        >
                                            {historyRow.id}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                                cursor: 'copy'
                                            }}
                                            title={historyRow.user.publicAddress}
                                            onClick={() => navigator.clipboard.writeText(historyRow.user.publicAddress)}
                                        >
                                            {formatHash(historyRow.user.publicAddress)}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={historyRow.steamBotId}
                                        >
                                            {historyRow.steamBotId}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={historyRow.action}
                                        >
                                            {historyRow.action}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={historyRow.checkoutType}
                                        >
                                            {historyRow.checkoutType}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={historyRow.originId}
                                        >
                                            {historyRow.originId}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={historyRow.offerId}
                                        >
                                            {historyRow.offerId}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={historyRow.price}
                                        >
                                            {historyRow.price}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={historyRow.result}
                                        >
                                            {historyRow.result}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={totalProductHistory || 0}
                        rowsPerPage={rowsPerPageHistory}
                        page={pageHistory}
                        onPageChange={handleChangePageHistory}
                        onRowsPerPageChange={handleChangeRowsPerPageHistory}
                    />
                </Box>
            </>
        </Modal>
    )
}