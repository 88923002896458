export function formatHash(str, maxLength = 19) {
    if (!str || str.length <= maxLength) {
        return str;
    }

    const minLastPartLength = 4;
    const separatorLength = 3;
    const firstPartLength = maxLength - minLastPartLength - separatorLength;

    const formattedHash = [
        str.slice(0, firstPartLength),
        ...new Array(separatorLength).fill('.'),
        str.slice(-minLastPartLength),
    ];

    return formattedHash.join('');
}