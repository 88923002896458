import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('krakatoa-user-admin', null);
  const navigate = useNavigate();

  const authorize = async (data) => {
    setUser(data);
    localStorage.setItem('admin-token', JSON.stringify({ value: data.accessToken }));
    navigate('/dashboard/orders');
  }

  const logout = () => {
    setUser(null);
    localStorage.removeItem('admin-token');
    navigate('/login', { replace: true });
  }

  const value = useMemo(
    () => ({
      user,
      authorize,
      logout
    }),
    [user]
  );

  return <AuthContext.Provider value={value}> { children } </AuthContext.Provider>
};

export const useAuth = () => {
  return useContext(AuthContext);
};