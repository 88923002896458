import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Settings from './pages/Settings';
import StatisticBots from "./pages/Statistic/StatisticBots";
import SteamBots from "./pages/SteamBots";
import FAQ from "./pages/FAQ/FAQ";
import { FaqCreateQuestion } from "./pages/FAQ/FaqCreateQuestion";
import Products from "./pages/Product/Products";
import HomepageEdits from "./pages/HomepageEdits";
import { Orders } from "./pages/Order/Orders";
import { OrdersProducts } from "./pages/Order/OrdersProducts";
import Packs from './pages/Packs/Packs';
import {StatisticMarket} from "./pages/Statistic/StatisticMarket";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'statistic',
          children: [
            { path: 'bots', element: <StatisticBots /> },
            { path: 'market', element: <StatisticMarket /> },
          ]
        },
        { path: 'steam_bots', element: <SteamBots /> },
        { path: 'settings', element: <Settings /> },
        { path: 'faq', element: <FAQ /> },
        { path: 'faq/create', element: <FaqCreateQuestion /> },
        { path: 'orders', element: <Orders /> },
        { path: 'orders/:id', element: <OrdersProducts /> },
        { path: 'products', element: <Products /> },
        { path: 'packs', element: <Packs /> },
        { path: 'homepage_edits', element: <HomepageEdits /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/orders" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
