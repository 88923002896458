import client from "./client";

const getStatistic = (params) => {
    return client.get('/statistic', { params })
        .then(({data}) => data)
        .catch(err => {
            const message = err.message;
            const error = { message };
            return Promise.reject(error);
        });
}


const getBotAccounts = () => {
    return client.get('/steam-bot/accounts').then(({ data }) => data).catch((err) => Promise.reject({ message: err.message }))
}

const getMarketStatistics = () => {
    return client.get('/statistic/market').then(({ data }) => data).catch((err) => Promise.reject({ message: err.message }))
}

export {getStatistic, getBotAccounts, getMarketStatistics};