import client from "./client";

const getOrders = (params) => {
    return client.get('/orders', { params })
        .then(({ data }) => data)
        .catch((err) => {
            return Promise.reject({ message: err?.message || 'Something went wrong...'});
        });
}

const getProducts = (orderId, params) => {
    return client.get(`/orders/${orderId}`, { params })
        .then(({ data }) => data)
        .catch((err) => {
            return Promise.reject({ message: err?.message || 'Something went wrong...'});
        });
}

const getWithdrawFeeSignature = async (ids) => {
    return client.get(`/orders/withdraw-fee-request${ids.map((id, index) => !index ? `?orderIds=${id}` : `&orderIds=${id}`).join('')}`)
}

const sendWithdrawTransactionData = async (body) => {
    return client.post(`/orders/withdraw-fee-request`, body)
}

const processAllOrders = async () => {
    return client.get(`/orders/processing-order-request`)
}

const sendProcessedData = async (body) => {
    return client.post(`/orders/processing-order-request`, body)
}


const getAmountOnContract = async () => {
    return client.get(`/orders/await-amount`)
}

export {
    getOrders,
    getProducts,
    getWithdrawFeeSignature,
    sendWithdrawTransactionData,
    processAllOrders,
    sendProcessedData,
    getAmountOnContract
}