import React, { useEffect, useState } from 'react';
import { CircularProgress, IconButton, MenuItem, Modal, Select, Button, InputLabel } from '@mui/material';

import SkinGroupAutocomplete from './SkinGroupAutocomplete';

import Iconify from 'src/components/Iconify';

import { getGroupDetails } from 'src/api/packs';

import styles from './Packs.module.scss';

const MODAL_STYLE = {
  textAlign: 'center',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 1050,
  maxHeight: '90vh',
  minHeight: '70vh',
  bgcolor: 'background.paper',
  boxShadow: 10,
  p: 4,
  overflow: 'auto',
};

const anyOption = {
  id: 'any',
  name: 'All items',
  count: 0,
  lowestPrice: 0,
  highestPrice: 0,
  lowestOfferPrice: 0,
  highestOfferPrice: 0,
  hasStatTrak: false,
  hasSouvenir: false,
  internalName: 'any',
  steamAveragePrice: 0,
  isPossible: true,
};

const NewFilters = ({ isVisible, onClose, onSubmit }) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupExteriorOptions, setGroupExteriorOptions] = useState({ isLoading: false, options: null });
  const [exterior, setExterior] = useState(null);

  const resetAll = () => {
    setSelectedGroup(null);
    setGroupExteriorOptions({ isLoading: false, options: null });
    setExterior(null)
  }

  const onSave = (group, condition) => {
    onSubmit({group, condition})
    resetAll();
    onClose();
  }

  useEffect(() => {
    const getExteriorOptions = async (groupId) => {
      try {
        setGroupExteriorOptions({ isLoading: true, options: null });
        const { exteriors } = await getGroupDetails(groupId);
        if (exteriors.length) {
          setGroupExteriorOptions({ isLoading: true, options: [anyOption, ...exteriors] });
          setExterior(anyOption)
        }
      } catch (error) {
      } finally {
        setGroupExteriorOptions((prev) => ({ ...prev, isLoading: false }));
      }
    };
    selectedGroup
      ? getExteriorOptions(selectedGroup?.id)
      : setGroupExteriorOptions({ isLoading: false, options: null });
  }, [selectedGroup && selectedGroup.id]);

  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      sx={MODAL_STYLE}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <section className={styles.filterModal}>
        <IconButton
          onClick={() => {
            onClose();
            resetAll();
          }}
          className={styles.closeBtn}
        >
          <Iconify icon={'material-symbols:close'} width={25} height={25} />
        </IconButton>
        <h4>Add new filter</h4>

        <SkinGroupAutocomplete onSelect={setSelectedGroup} />
        {groupExteriorOptions.isLoading && <CircularProgress sx={{ margin: '0 auto' }} />}
        {groupExteriorOptions.options && (
          <>
          <Select
            labelId="exterior"
            label="Select condition"
            className=''
            id="exterior"
            defaultValue={groupExteriorOptions.options[0]}
            value={exterior}
            onChange={(e) => {
              setExterior(e.target.value);
            }}
            sx={{ textTransform: 'capitalize' }}
          >
            {groupExteriorOptions.options.map((option) => (
              <MenuItem value={option} sx={{ textTransform: 'capitalize' }}>
                {option?.name} {option.hasSouvenir && 'Souvenir'} {option.hasStatTrak && 'Souvenir'}
              </MenuItem>
            ))}
          </Select>
          </>
        )}
        <div>
          <Button onClick={() => onSave(selectedGroup, exterior)}>Save</Button>
        </div>
      </section>
    </Modal>
  );
};

export default NewFilters;
