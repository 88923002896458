import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { MenuItem, Modal, Select, IconButton, Paper, Button, Input, Link } from '@mui/material';
import { Formik, FieldArray, useFormikContext } from 'formik';
import * as Yup from 'yup';

import NewFilters from './NewFilters';

import { getPackDetails, postPack, updatePack } from 'src/api/packs';

import Iconify from '../../components/Iconify';

import styles from './Packs.module.scss';
import { toDisplayFormat } from 'src/utils/textTransform';

const MODAL_STYLE = {
  textAlign: 'center',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 1050,
  maxHeight: '90vh',
  minHeight: '70vh',
  bgcolor: 'background.paper',
  boxShadow: 10,
  p: 4,
  overflow: 'auto',
};

const HomepageSection = {
  MAIN: 'main',
  PACKS_SLIDER: 'packs-slider',
  DETAILED_LIST: 'detailed-list',
  NONE: 'none',
};

const initialPackValues = {
  name: '',
  description: '',
  backgroundImage: undefined,
  contentImage: undefined,
  appId: 1,
  filters: [],
  section: HomepageSection.NONE,
  sectionTitle: '',
};

const Details = ({ isVisible, onClose, selectedPackAlias, filters, onUpdate, onSubmit }) => {
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isVisibleFilterSelector, setIsVisibleFilterSelector] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const addGroupCallback = useRef(null);

  const fetchPackDetails = async (alias) => {
    try {
      const response = await getPackDetails(alias);
      setDetails({
        ...response,
        filters: response.filters.map((filter) => ({
          ...filter,
          ...(filter?.exteriorTag?.name
            ? {
                condition: {
                  name: filter?.exteriorTag?.name,
                  hasSouvenir: filter.hasSouvenir,
                  hasStatTrak: filter.hasStatTrak,
                  internalName: filter?.exteriorTag?.internalName,
                },
              }
            : {}),
        })),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values) => {
    const { backgroundImage, contentImage, filters, ...pack } = values;

    const formData = new FormData();
    typeof backgroundImage !== 'string' && formData.append('backgroundImage', backgroundImage);
    typeof contentImage !== 'string' && formData.append('contentImage', contentImage);
    pack.name !== details?.name && formData.append('name', pack.name);
    pack.description !== details?.description && formData.append('description', pack.description);
    pack.orderBy && pack.orderBy !== details?.orderBy && formData.append('orderBy', pack.orderBy);
    pack.section && pack.section !== details?.section && formData.append('section', pack.section);
    pack.sectionTitle &&
      pack.sectionTitle !== details?.sectionTitle &&
      formData.append('sectionTitle', pack.sectionTitle);

    const formattedGroups = filters
      .map((item) => ({
        groupId: item.group.id,
        ...(!item?.condition?.internalName ||
        item?.condition?.internalName === 'any' ||
        item?.exteriorTag?.internalName === 'any'
          ? {}
          : {
              exterior: item?.condition?.internalName || item?.exteriorTag?.internalName,
              hasStatTrak:
                typeof item?.condition?.hasStatTrak !== 'undefined' ? item?.condition?.hasStatTrak : item?.hasStatTrak,
              hasSouvenir:
                typeof item?.condition?.hasSouvenir !== 'undefined' ? item?.condition?.hasSouvenir : item?.hasSouvenir,
            }),
      }))
      .sort((a, b) => (a.groupId < b.groupId ? -1 : 1));

    if (
      JSON.stringify(formattedGroups) !==
      JSON.stringify(details?.filters?.sort((a, b) => (a.groupId < b.groupId ? -1 : 1)))
    ) {
      formattedGroups.length &&
        formData.append(
          'filters',
          formattedGroups.map((group) => JSON.stringify(group))
        );
    }

    try {
      const response = selectedPackAlias ? await updatePack(details.id, formData) : await postPack(formData);
      const result = {
        alias: response.alias,
        id: response.id,
        backgroundImageUrl: backgroundImage,
        contentImageUrl: contentImage,
        description: pack.description,
        orderBy: pack?.orderBy,
        name: pack.name,
      };
      selectedPackAlias ? onUpdate(result) : onSubmit(result);
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  useEffect(() => {
    if (selectedPackAlias) {
      fetchPackDetails(selectedPackAlias);
      setIsEditMode(false);
      setIsLoading(false);
    } else {
      setIsEditMode(true);
      setIsLoading(false);
    }
  }, [isVisible, selectedPackAlias]);

  const parseImageLink = (link) => {
    const STEAM_IMAGE_API_URL = 'https://steamcommunity-a.akamaihd.net/economy/image/';
    return link && (link.includes(STEAM_IMAGE_API_URL) ? link : `${STEAM_IMAGE_API_URL}${link}`);
  };

  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      sx={MODAL_STYLE}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {isLoading ? (
        <span>Loading...</span>
      ) : (
        <Formik
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          initialValues={
            details
              ? {
                  ...initialPackValues,
                  ...details,
                  backgroundImage: details.backgroundImageUrl,
                  contentImage: details.contentImageUrl,
                }
              : initialPackValues
          }
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            sectionTitle: Yup.string().required('Required'),
            backgroundImage: Yup.lazy((value) => {
              switch (typeof value) {
                case 'object':
                  return Yup.mixed().required('Background image is required');
                default:
                  return Yup.string().required('Background image is required');
              }
            }),
            contentImage: Yup.lazy((value) => {
              switch (typeof value) {
                case 'object':
                  return Yup.mixed().required('Content image is required');
                default:
                  return Yup.string().required('Content image is required');
              }
            }),
            orderBy: Yup.number(),
            appId: Yup.number().required('Required'),
            // add format of filtes
            filters: Yup.array(),
            // section: Yup.any()
          })}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <section className={styles.details}>
              <div className={styles.formControls}>
                {isEditMode && !!selectedPackAlias && (
                  <IconButton
                    onClick={() => {
                      setIsEditMode(false);
                    }}
                  >
                    <Iconify icon={'mdi:cancel-octagon'} width={25} height={25} color="red" />
                  </IconButton>
                )}
                <Button
                  onClick={() => {
                    if (isEditMode) {
                      handleSubmit(true);
                    } else {
                      setIsEditMode(true);
                    }
                  }}
                  color={isEditMode ? 'success' : 'primary'}
                >
                  {isEditMode ? (
                    <>
                      <span>Save</span> <Iconify icon={'mingcute:save-fill'} width={15} height={15} />
                    </>
                  ) : (
                    <>
                      <span>Edit</span> <Iconify icon={'material-symbols:edit'} width={15} height={15} />
                    </>
                  )}
                </Button>
                <IconButton
                  onClick={() => {
                    onClose();
                    setIsEditMode(false);
                    setDetails({});
                  }}
                  className={styles.closeBtn}
                >
                  <Iconify icon={'material-symbols:close'} width={25} height={25} />
                </IconButton>
              </div>
              <div className={styles.images}>
                <div className={styles.image}>
                  <img
                    src={
                      !isEditMode
                        ? details.backgroundImageUrl
                        : typeof values.backgroundImage === 'string'
                        ? values.backgroundImage
                        : typeof values.backgroundImage === 'object'
                        ? URL.createObjectURL(values.backgroundImage)
                        : ''
                    }
                    alt=""
                  />
                  {!!isEditMode && !values.backgroundImage && <span>Background image</span>}
                  <div className={styles.image_btns}>
                    {isEditMode && (
                      <Button aria-label="add" color="success" variant="contained" className={styles.image_btn}>
                        <label>
                          <Iconify icon={'material-symbols:add'} width={22} height={22} color="white" />
                          <input
                            id="backgroundImage"
                            name="backgroundImage"
                            type="file"
                            onChange={(e) => setFieldValue('backgroundImage', e.currentTarget.files[0])}
                            onClick={(e) => {
                              e.currentTarget.value = null;
                            }}
                            accept="image/png"
                            hidden
                          />
                        </label>
                      </Button>
                    )}
                  </div>
                  {errors.backgroundImage && touched.backgroundImage && (
                    <span className={styles.error}>{errors.backgroundImage}</span>
                  )}
                </div>
                <div className={styles.image}>
                  <img
                    src={
                      !isEditMode
                        ? details.contentImageUrl
                        : typeof values.contentImage === 'string'
                        ? values.contentImage
                        : typeof values.contentImage === 'object'
                        ? URL.createObjectURL(values.contentImage)
                        : ''
                    }
                    alt=""
                  />
                  {!!isEditMode && !values.contentImage && <span>Content image</span>}
                  <div className={styles.image_btns}>
                    {isEditMode && (
                      <Button aria-label="add" color="success" variant="contained" className={styles.image_btn}>
                        <label>
                          <Iconify icon={'material-symbols:add'} width={22} height={22} color="white" />
                          <input
                            id="contentImage"
                            name="contentImage"
                            type="file"
                            onChange={(e) => setFieldValue('contentImage', e.currentTarget.files[0])}
                            onClick={(e) => {
                              e.currentTarget.value = null;
                            }}
                            accept="image/png"
                            hidden
                          />
                        </label>
                      </Button>
                    )}
                  </div>
                  {errors.contentImage && touched.contentImage && (
                    <span className={styles.error}>{errors.contentImage}</span>
                  )}
                </div>
              </div>
              {!!errorMessage && <p className={classNames(styles.error, styles.error_general)}>{errorMessage}</p>}
              <div className={styles.details_inputGroup}>
                <label>Name:</label>
                {isEditMode ? (
                  <>
                    <Input
                      value={values.name}
                      name="name"
                      id="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={styles.input}
                    />
                    {errors.name && touched.name && <span className={styles.error}>{errors.name}</span>}
                  </>
                ) : (
                  <h4 className={styles.name}>{details.name}</h4>
                )}
              </div>
              <div className={styles.details_inputGroup}>
                <label>Section title:</label>
                {isEditMode ? (
                  <>
                    <Input
                      value={values.sectionTitle}
                      name="sectionTitle"
                      id="sectionTitle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={styles.input}
                    />
                    {errors.sectionTitle && touched.sectionTitle && <span className={styles.error}>{errors.sectionTitle}</span>}
                  </>
                ) : (
                  <h4 className={styles.sectionTitle}>{details.sectionTitle}</h4>
                )}
              </div>
              <div className={styles.details_inputGroup}>
                <label>Description:</label>
                {isEditMode ? (
                  <>
                    <Input
                      value={values.description}
                      name="description"
                      id="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={styles.input}
                    />
                    {errors.description && touched.description && (
                      <span className={styles.error}>{errors.description}</span>
                    )}
                  </>
                ) : (
                  <p className={styles.description}>{details.description}</p>
                )}
              </div>
              <div className={styles.details_inputGroup}>
                <label>Ordinal:</label>
                {isEditMode ? (
                  <>
                    <Input
                      value={values.orderBy}
                      name="orderBy"
                      id="orderBy"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={styles.input}
                    />
                    {errors.orderBy && touched.orderBy && <span className={styles.error}>{errors.orderBy}</span>}
                  </>
                ) : (
                  <p className={styles.description}>{details.orderBy}</p>
                )}
              </div>
              <div className={styles.details_inputGroup}>
                <label>Homepage section:</label>
                {isEditMode ? (
                  <>
                    <Select
                      value={values.section}
                      name="section"
                      id="section"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={styles.input}
                    >
                      {Object.values(HomepageSection).map((section) => (
                        <MenuItem key={section} value={section}>
                          {toDisplayFormat(section)}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.orderBy && touched.orderBy && <span className={styles.error}>{errors.orderBy}</span>}
                  </>
                ) : (
                  <p className={styles.description}>{toDisplayFormat(details.section)}</p>
                )}
              </div>
              <div className={styles.details_inputGroup}>
                <label>Selected items:</label>
                <FieldArray
                  name={`filters`}
                  render={(filterConditionsArrayHelpers) => (
                    <div className={styles.filterGroup_papers}>
                      {values.filters &&
                        values.filters.map((condition, index) => {
                          return (
                            Object.keys(condition).length && (
                              <Paper elevation={8} className={styles.filterCondition}>
                                {isEditMode && (
                                  <IconButton
                                    className={styles.filterCondition_removeBtn}
                                    onClick={() => filterConditionsArrayHelpers.remove(index)}
                                  >
                                    <Iconify icon={'ic:baseline-remove-circle'} width={25} height={25} color="red" />
                                  </IconButton>
                                )}
                                <img
                                  className={styles.filterCondition_image}
                                  src={parseImageLink(
                                    condition?.group?.payload?.group?.contentImageUrl ||
                                      condition?.group.contentImageUrl
                                  )}
                                  height={50}
                                />
                                <p className={styles.filterCondition_filter}>{condition?.group?.name}:</p>
                                {!condition.condition && 'All conditions'}
                                {condition.condition && (
                                  <p className={styles.filterCondition_name}>{condition.condition?.name}</p>
                                )}
                                {condition.condition?.hasStatTrak && (
                                  <p className={styles.filterCondition_name}>StatTrak</p>
                                )}
                                {condition.condition?.hasSouvenir && (
                                  <p className={styles.filterCondition_name}>Souvenir</p>
                                )}
                              </Paper>
                            )
                          );
                        })}
                      {isEditMode && (
                        <Paper elevation={8} className={classNames(styles.filterCondition, styles.addCondition)}>
                          <Button
                            onClick={() => {
                              addGroupCallback.current = (newFilter) => filterConditionsArrayHelpers.push(newFilter);
                              setIsVisibleFilterSelector(true);
                            }}
                          >
                            Add item
                          </Button>
                        </Paper>
                      )}
                      {errors?.filterGroups?.[i]?.filterConditions && touched?.filterGroups?.[i]?.filterConditions && (
                        <span style={{ color: 'red' }}>{errors?.filterGroups?.[i]?.filterConditions}</span>
                      )}
                    </div>
                  )}
                />
              </div>

              <div></div>
              <NewFilters
                filters={filters}
                onSubmit={(filter) => addGroupCallback.current(filter)}
                isVisible={isVisibleFilterSelector}
                onClose={() => setIsVisibleFilterSelector(false)}
              />
              {/* <PackProducts
                isVisible={isPackProductsVisible}
                products={details.productPacks}
                onClose={() => setIsPackProductsVisible(false)}
              /> */}
            </section>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default Details;
