import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Table,
  Paper,
  Stack,
  Select,
  Tooltip,
  Snackbar,
  MenuItem,
  TableRow,
  Checkbox,
  Container,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Input,
} from '@mui/material';
import Web3 from 'web3';
import { LoadingButton } from '@mui/lab';

import { UserListHead } from '../../sections/@dashboard/user';

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { OrderStatusLabel } from '../../components/OrderStatusLabel';

import { getOrders } from '../../api';

import { OrderFilter } from '../../constants';
import { filterDuplicates } from '../../utils/filterDuplicates';
import { toDisplayFormat } from '../../utils/textTransform';
import { getEthProps, checkTransaction } from '../../utils/web3';
import {
  getWithdrawFeeSignature,
  sendWithdrawTransactionData,
  processAllOrders,
  sendProcessedData,
  getAmountOnContract,
} from 'src/api/orders';
import { formatHash } from '../../utils/formatHash';
import { useNavigate } from 'react-router-dom';

const TABLE_HEAD = [
  { id: 'select', label: '#', alignRight: false },
  { id: 'orderId', label: 'ID', alignRight: false },
  { id: 'userPublicAddress', label: "User's public address", alignRight: false },
  { id: 'transactionHash', label: 'Transaction Hash', alignRight: false },
  { id: 'totalPrice', label: 'Total Price', alignRight: false },
  { id: 'itemsCount', label: 'Items Count', alignRight: false },
  { id: 'fee', label: 'Fee', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'contractStatus', label: 'Contract status', alignRight: false },
];

const withdrawType = {
  FEE: 'fee',
  FOUNDS: 'founds',
};

const amountType = {
  TOTAL: 'total',
  PROCESSING_P2C: 'processingP2C',
  APP_FEE: 'appFee',
  REFUND: 'refund',
  WITHDRAW: 'withdraw',
};

const paperStyle = {
  padding: '18px 25px',
  width: '18%',
  fonsSize: '20px',
  lineHeight: '30px',
  flexWrap: 'wrap',
};

const DIGITS_AFTER_DECIMAL = 14;

export function Orders() {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [isSelectAllOrders, setIsSelectAllOrders] = useState(false);

  const [orderStatusFilter, setOrderStatusFilter] = useState(OrderFilter.COMPLETED_NOT_WITHDRAWN);
  const [productIdFilter, setProductIdFilter] = useState(null);

  const [loader, setLoader] = useState(false);
  const [orderBy, setOrderBy] = useState('asc');

  const [orderList, setOrderList] = useState([]);
  const [totalOrders, setTotalOrders] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [isWithdrawing, setIsWithdrawing] = useState(null);

  const [amount, setAmount] = useState(null);

  const setSnackBarStatus = (message, isError) => {
    setMessage(message);
    setIsError(isError);
    setShowSnackbar(true);
  };

  const toggleSelectOrder = (orderId) => {
    setSelectedOrderIds((prev) => (prev.includes(orderId) ? prev.filter((id) => orderId !== id) : [...prev, orderId]));
  };

  const checkSelectAllOrders = () => {
    if (!orderList.length) {
      return setIsSelectAllOrders(false);
    }

    const orderListIds = orderList.map(({ id }) => id);

    const selectAllOrdersStatus = orderListIds.every((id) => selectedOrderIds.includes(id));

    setIsSelectAllOrders(selectAllOrdersStatus);
  };

  const updateSelectAllOrders = () => {
    const orderListIds = orderList.map(({ id }) => id);

    setSelectedOrderIds((prevSelectedOrderIds) => {
      const nextSelectAllOrders = orderListIds.every((id) => prevSelectedOrderIds.includes(id));

      return nextSelectAllOrders
        ? prevSelectedOrderIds.filter((id) => !orderListIds.includes(id))
        : filterDuplicates([...prevSelectedOrderIds, ...orderListIds]);
    });
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const itemsPerPage = +event.target.value;

    setRowsPerPage(itemsPerPage);
    setPage(0);
  };

  const getOrderList = ({ limit, offset, status }) => {
    setLoader(true);

    const params = {
      limit: limit || rowsPerPage,
      offset: offset || page * rowsPerPage,
      status: status || orderStatusFilter,
    };

    if (productIdFilter) {
      params.productId = productIdFilter;
    }

    getOrders(params)
      .then(({ data, total }) => {
        setOrderList(data);
        setTotalOrders(total);
      })
      .catch((err) => setSnackBarStatus(err.message, true))
      .finally(() => setLoader(false));
  };

  const statusOnChange = (e) => {
    setOrderStatusFilter(e.target.value);
    setPage(0);
    setSelectedOrderIds([]);
  };

  const productIdOnChange = (e) => {
    setProductIdFilter(e.target.value);
    setPage(0);
  };

  const fetchAmount = async () => {
    const { data } = await getAmountOnContract();
    setAmount(data?.awaitAmounts);
  };

  useEffect(() => {
    fetchAmount();
  }, []);

  useEffect(() => {
    checkSelectAllOrders();
  }, [orderList, selectedOrderIds]);

  useEffect(() => {
    getOrderList({});
  }, [page, rowsPerPage, orderStatusFilter, productIdFilter]);

  const onWithdraw = async (type) => {
    try {
      setIsWithdrawing(type);

      const { provider, address } = await getEthProps();
      const signature =
        type === withdrawType.FOUNDS ? await processAllOrders() : await getWithdrawFeeSignature(selectedOrderIds);
      const hash = await provider.request({
        method: 'eth_sendTransaction',
        params: [{ from: address[0], ...signature.data.methodRequest }],
      });

      const web3 = new Web3(provider);
      await checkTransaction(web3, hash);

      const trasactionData = {
        orderIds: signature.data.orderIds.map((id) => String(id)),
        hash: signature.data.hash,
        transactionHash: hash,
      };
      type === withdrawType.FOUNDS
        ? await sendProcessedData(trasactionData)
        : await sendWithdrawTransactionData(trasactionData);
    } catch (e) {
      setSnackBarStatus(e?.response?.data?.message || e?.message, true);
    } finally {
      setIsWithdrawing(null);
    }
  };

  return (
    <Page title="Orders">
      <Container maxWidth="xl" direction="row">
        <Stack direction="row" mb={2}>
          <Typography variant="h4" gutterBottom>
            Orders
          </Typography>
          <Snackbar
            open={showSnackbar}
            message={message}
            severity={isError && 'error'}
            onClose={() => setShowSnackbar(false)}
            autoHideDuration={3000}
          />
        </Stack>
        <Stack direction="row" mb={2}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Paper elevation={8} sx={ {...paperStyle, borderWidth: '1px', borderStyle: 'solid', borderColor: 'rgba(223, 227, 232, .3)'} }>
              <Typography>Total</Typography>
              {Number(amount?.[amountType.TOTAL]?.toFixed(DIGITS_AFTER_DECIMAL)) || `- `}USDT
            </Paper>
            <Paper elevation={2} sx={paperStyle}>
              <Typography>Processing P2C</Typography>
              {Number(amount?.[amountType.PROCESSING_P2C]?.toFixed(DIGITS_AFTER_DECIMAL)) || `- `}USDT
            </Paper>
            <Paper elevation={2} sx={paperStyle}>
              <Typography>App Fee</Typography>
              {Number(amount?.[amountType.APP_FEE]?.toFixed(DIGITS_AFTER_DECIMAL)) || `- `}USDT
            </Paper>
            <Paper elevation={2} sx={paperStyle}>
              <Typography>Refund</Typography>
              {Number(amount?.[amountType.REFUND]?.toFixed(DIGITS_AFTER_DECIMAL)) || `- `}USDT
            </Paper>
            <Paper elevation={2} sx={paperStyle}>
              <Typography>Withdraw</Typography>
              {Number(amount?.[amountType.WITHDRAW]?.toFixed(DIGITS_AFTER_DECIMAL)) || `- `}USDT
            </Paper>
          </Box>
        </Stack>
        <Stack direction="row" mb={5}>
          <Container direction="row" sx={{ ml: 0 }}>
            Select All ({selectedOrderIds.length}){' '}
            <Checkbox checked={isSelectAllOrders} onClick={updateSelectAllOrders} />
            <Select
              onChange={statusOnChange}
              defaultValue={OrderFilter.COMPLETED_NOT_WITHDRAWN}
              sx={{ width: '300px', ml: '25px' }}
            >
              {Object.values(OrderFilter).map((status) => (
                <MenuItem key={status} value={status}>
                  {toDisplayFormat(status)}
                </MenuItem>
              ))}
            </Select>
            <Input onChange={productIdOnChange} sx={{ width: '100px', ml: '30px' }} placeholder="product id" />
          </Container>
          <Tooltip title={!selectedOrderIds.length && 'Select orders for withdrawal'}>
            <LoadingButton
              sx={{ color: '#C4CDD5' }}
              disabled={!selectedOrderIds.length}
              loading={isWithdrawing === withdrawType.FEE}
              onClick={() => onWithdraw(withdrawType.FEE)}
            >
              withdraw fees from completed orders
            </LoadingButton>
          </Tooltip>
          <LoadingButton
            sx={{ color: '#C4CDD5' }}
            loading={isWithdrawing === withdrawType.FOUNDS}
            onClick={() => onWithdraw(withdrawType.FOUNDS)}
          >
            withdraw funds from all orders
          </LoadingButton>
        </Stack>

        {loader ? (
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="center" mb={5} style={{ height: '25vh' }}>
              <CircularProgress />
            </Stack>
          </Container>
        ) : (
          <Card>
            <Scrollbar>
              <TableContainer sx={{ width: '100%', minWidth: 800 }}>
                <Table>
                  <UserListHead order={orderBy} headLabel={TABLE_HEAD} rowCount={orderList?.length} />
                  <TableBody>
                    {orderList?.map((order) => {
                      const {
                        id,
                        userId,
                        user,
                        transactionHash = 'undefined',
                        totalPrice,
                        totalPriceUsd,
                        status,
                      } = order;
                      const { feeEth = 'undefined', status: contractStatus = 'undefined' } = order.smartContractInfo;
                      const itemsCount = order.itemsCount || 0;

                      return (
                        <TableRow key={id}>
                          <TableCell align="left" style={{ overflow: 'hidden', maxWidth: 50 }}>
                            <Checkbox
                              checked={selectedOrderIds.includes(order.id)}
                              onClick={() => toggleSelectOrder(order.id)}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ '&:hover': { backgroundColor: '#242f3c' } }}
                            style={{
                              overflow: 'hidden',
                              minWidth: 80,
                              maxWidth: 50,
                              textOverflow: 'ellipsis',
                              cursor: 'pointer',
                            }}
                            onClick={() => navigate('/dashboard/orders/' + id)}
                          >
                            {id}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ overflow: 'hidden', minWidth: 150, textOverflow: 'ellipsis' }}
                          >
                            <Tooltip title={user?.publicAddress ? user.publicAddress : userId} enterTouchDelay={0}>
                              <span>{user?.publicAddress ? formatHash(user.publicAddress) : userId}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ overflow: 'hidden', minWidth: 100, maxWidth: 150, textOverflow: 'ellipsis' }}
                          >
                            <Tooltip title={transactionHash} enterTouchDelay={0}>
                              <span>{formatHash(transactionHash)}</span>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ overflow: 'hidden', minWidth: 100, textOverflow: 'ellipsis' }}
                          >
                            {totalPrice}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ overflow: 'hidden', minWidth: 100, textOverflow: 'ellipsis' }}
                          >
                            {itemsCount}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ overflow: 'hidden', minWidth: 100, textOverflow: 'ellipsis' }}
                          >
                            {feeEth}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ overflow: 'hidden', minWidth: 120, maxWidth: 160, textOverflow: 'clip' }}
                          >
                            {OrderStatusLabel(status)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ overflow: 'hidden', minWidth: 100, textOverflow: 'ellipsis' }}
                          >
                            {OrderStatusLabel(contractStatus)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {orderList?.length ? null : (
                      <TableRow style={{ height: 250 }}>
                        <TableCell colSpan={10} align="center">
                          {' '}
                          No Orders Was Found{' '}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
              component="div"
              count={totalOrders || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
    </Page>
  );
}
