import {Box, Modal, Table, TableBody, TableCell, TablePagination, TableRow, Typography} from "@mui/material";
import {UserListHead} from "../../sections/@dashboard/user";
import {formatHash} from "../../utils/formatHash";
import React, {useEffect, useState} from "react";
import {getOffers} from "../../api";
import Iconify from "../../components/Iconify";
import {toDisplayFormat} from "../../utils/textTransform";


const MODAL_STYLE = {
    textAlign: 'center',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 1050,
    maxHeight: '90vh',
    minHeight: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 10,
    p: 4,
};

const TABLE_HEAD = [
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'publicAddress', label: 'Buyer Address', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false },
    { id: 'quantity', label: 'Quantity', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'groupId', label: 'Group Id', alignRight: false },
    { id: 'productId', label: 'Product Id', alignRight: false },
    { id: 'exterior', label: 'Exterior', alignRight: false },
    { id: 'createdAt', label: 'Created At', alignRight: false },
];


const getIcon = (name) => <Iconify icon={name} width={25} height={25} />;

export function OffersModal(props) {
    const {openModal, setOpenModal, currentProduct, setCurrentProduct, setSnackBarStatus, setLoader} = props;

    const [offers, setOffers] = useState([]);
    const [totalOffers, setTotalOffers] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        const itemsPerPage = +event?.target?.value || 0;

        setRowsPerPage(itemsPerPage);
        setPage(0);
    };

    const handleModalClose = () => {
        setOpenModal(false);
        setCurrentProduct(null);
        setPage(0);
        setOffers([]);
    };

    const handleGetOffers = () => {
        const params = {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            productId: currentProduct.id,
        };

        getOffers(params)
            .then(({ data, total }) => {
                setOffers(data);
                setTotalOffers(total);
            })
            .catch((err) => setSnackBarStatus(err.message, true))
            .finally(() => setLoader(false));
    };

    useEffect(() => {
        if (currentProduct && openModal) {
            handleGetOffers();
        }
    }, [currentProduct, page, rowsPerPage, openModal]);

    return (
        <Modal
            open={openModal}
            onClose={handleModalClose}
            sx={MODAL_STYLE}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <div
                    style={{
                        position: 'absolute',
                        right: 15,
                        top: 15,
                        cursor: 'pointer',
                    }}
                    onClick={handleModalClose}
                >
                    {getIcon('material-symbols:close')}
                </div>
                <Box style={{
                    position: 'relative',
                    height: '100%',
                    overflow: 'auto',
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Product: {currentProduct?.id}
                    </Typography>

                    <Table>
                        <UserListHead headLabel={TABLE_HEAD} rowCount={offers?.length} />
                        <TableBody>
                            {offers?.map((offer) => {
                                return (
                                    <TableRow hover key={offer.id} tabIndex={-1} role="checkbox">
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={offer.id}
                                        >
                                            {offer.id}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                                cursor: 'copy'
                                            }}
                                            title={offer.buyer.publicAddress}
                                            onClick={() => navigator.clipboard.writeText(offer.buyer.publicAddress)}
                                        >
                                            {formatHash(offer.buyer.publicAddress)}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={offer.type}
                                        >
                                            {offer.type}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={offer.price}
                                        >
                                            {offer.price}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={offer.quantity}
                                        >
                                            {offer.quantity}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={offer.status}
                                        >
                                            {toDisplayFormat(offer.status)}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={offer.groupId}
                                        >
                                            {offer.groupId}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={offer.productId || 'null'}
                                        >
                                            {offer.productId || 'null'}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={offer.exterior || 'null'}
                                        >
                                            {offer.exterior || 'null'}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 140,
                                                textOverflow: 'ellipsis',
                                            }}
                                            title={new Date(offer.createdAt).toUTCString()}
                                        >
                                            {new Date(offer.createdAt).toUTCString()}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <TablePagination
                        rowsPerPageOptions={[5, 10]}
                        component="div"
                        count={totalOffers || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            </>
        </Modal>
    )
}