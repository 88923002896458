import PropTypes from 'prop-types';
// material
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// UserListHead.propTypes = {
//   order: PropTypes.oneOf(['asc', 'desc']),
//   orderBy: PropTypes.string,
//   rowCount: PropTypes.number,
//   headLabel: PropTypes.array,
//   numSelected: PropTypes.number,
//   onRequestSort: PropTypes.func,
//   onSelectAllClick: PropTypes.func,
// };

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            // sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}

            {/* <TableSortLabel */}
            {/*  hideSortIcon */}
            {/*  active={orderBy === headCell.id} */}
            {/*  direction={orderBy === headCell.id ? order : 'asc'} */}
            {/*  onClick={createSortHandler(headCell.id)} */}
            {/* > */}
            {/*  */}
            {/*  {orderBy === headCell.id ? ( */}
            {/*    <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box> */}
            {/*  ) : null} */}
            {/* </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
