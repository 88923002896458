import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, AlertTitle } from '@mui/material';
import { Button, Grid } from '@mui/material';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { confirmLogin } from '../../../api';
import { useAuth } from '../../../contexts/AuthProvider';

// ----------------------------------------------------------------------

export default function ConfirmLoginForm({ email, clearEmail, setLoader }) {
    const MINUTE = 60;

    const { authorize } = useAuth();

    const [codeExpirationTime, setCodeExpirationTime] = useState(MINUTE);

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCodeExpirationTime((codeExpirationTime) => {
                const nextTime = codeExpirationTime - 1;

                if (nextTime <= 0) {
                    clearInterval(interval);
                    clearEmail();
                }

                return nextTime;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const LoginSchema = Yup.object().shape({
        code: Yup.string().required('Code is required'),
    });

    const defaultValues = {
        code: '',
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (values) => {
        setLoader(true);
        confirmLogin({ ...values, email })
            .then((data) => authorize(data))
            .catch((err) => setHasError(true))
            .finally(() =>  setLoader(false));
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <div>The code will expire: 00:{codeExpirationTime < 10 && '0'}{codeExpirationTime}</div>
            <Stack spacing={3} sx={{ my: 2 }}>
                <RHFTextField name="code" label="Code" />

                {hasError && (
                    <Alert severity="error" onClose={() => setHasError(false)}>
                        <AlertTitle>Error</AlertTitle>
                        Something went wrong, please check your email or password
                    </Alert>
                )}
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <Button style={{ width: "100%" }} size="large" type="button" color="inherit" variant="contained" loading={isSubmitting} onClick={clearEmail}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Button style={{ width: "100%" }} size="large" type="submit" color="primary" variant="contained" loading={isSubmitting}>
                        Confirm
                    </Button>
                </Grid>
            </Grid>



        </FormProvider>
    );
}
