import client from "./client";

export const getEditorChoice = async (params) => {
    return await client.get('/group/editor-choice', { params })
        .then(({ data }) => data)
        .catch((err) => {
            return Promise.reject({ message: err?.message || 'Something went wrong...'});
        });
}

export const putEditorChoice = async (groups) => {
    return await client.put('/system-group/editor-choice', { groups })
        .then(({ data }) => data)
        .catch((err) => {
            return Promise.reject({ message: err?.message || 'Something went wrong...'});
        });
} 

export const getGroup = (params) => {
    return client.get('/group', { params })
        .then(({ data }) => data)
        .catch((err) => {
            return Promise.reject({ message: err?.message || 'Something went wrong...'});
        });
}