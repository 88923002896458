import { useEffect, useMemo, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { groupAutocomplete } from 'src/api/packs';

function SkinGroupAutocomplete({ onSelect }) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);


  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    groupAutocomplete(inputValue).then(({ hits }) => {
      setOptions(hits);
    });
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="skinGroup"
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No groups"
      getOptionKey={option => option.id}
      getOptionLabel={option => option?.payload?.group?.name}
      isOptionEqualToValue={option => option.id === value.id}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue] : options);
        setValue(newValue);
        onSelect(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        return <TextField {...params} label="Select group" fullWidth placeholder='Type here to search... '/>;
      }}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            onClick={(e) => {
              props.onClick(e);
            }}
          >
            <Grid container alignItems="center">
              <Grid item sx={{ marginRight: '8px', display: 'flex', width: 44, objectFit: 'contain' }}>
                <img src={option?.payload?.group?.contentImageUrl || ''} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px - 8px)', wordWrap: 'break-word' }}>
                {/* {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))} */}
                <Typography variant="body2" color="text.secondary">
                  {/* {option.structured_formatting.secondary_text} */}
                  {option.payload.group.name}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export default SkinGroupAutocomplete;
