import { useEffect, useState } from "react";
import { CircularProgress, Container, Stack, Typography } from "@mui/material";

import Page from "src/components/Page";
import FaqCreateQuestionForm from "../../sections/FAQ/CreateQuestionForm/CreateQuestionForm";

import { getFaqCategories } from "../../api/faq";


export function FaqCreateQuestion() {
    const [loader, setLoader] = useState(false);
    const [categories, setCategories] = useState([]);

    const setFaqCategories = () => {
        setLoader(true);
        getFaqCategories()
            .then((data) => {
                setCategories(data);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoader(false));
    }

    useEffect(() => {
        setFaqCategories();
    }, [])

    return (
        <Page title='FAQ: Create Question'>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        FAQ: Create Question
                    </Typography>
                </Stack>

                {loader ? (
                    <Container>
                        <Stack direction="row" alignItems="center" justifyContent="center" mb={5} style={{height: '100vh'}}>
                            <CircularProgress />
                        </Stack>
                    </Container>
                ) : (
                    <FaqCreateQuestionForm setLoader={setLoader} categories={categories}/>
                )}
            </Container>
        </Page>
    )
}