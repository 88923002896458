import client from './client';


const getFAQs = ({ offset, limit }) => {
    return client.get('/content/faq', {
        params: { offset, limit }
    })
        .then(({data}) => data)
        .catch(err =>  Promise.reject({ message: err.message }));
}

const getFaqCategories = () => {
    return client.get('/content/faq/category')
        .then(({ data }) =>  data)
        .catch(err =>  Promise.reject({ message: err.message }));
}

const createQuestion = ({title, categoryId, answer }) => {
    return client.post('/content/faq/add-question', { categoryId, title, answer})
        .then(({ data }) => data)
        .catch(err => Promise.reject({ message: err.message }));
}

export {
    getFAQs,
    getFaqCategories,
    createQuestion,
};