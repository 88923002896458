
const getMetamaskProvider = () => (window?.ethereum?.providers
  ? window?.ethereum?.providers?.find(currentProvider => !!currentProvider?.isMetaMask)
  : window?.ethereum?.isMetaMask && window?.ethereum);

const getEthProps = async () => {
  const provider = getMetamaskProvider();
  if (provider) {
    await provider.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {}
          }
        ]
      })
    const address = await provider.request({ method: 'eth_accounts' });
  
    return {provider, address}
  } else {
    throw new Error('Unable to detect Metamask')
  }
};

const checkTransaction = async (web3, hash) => {
  return await new Promise(resolve => {
    const interval = setInterval(async () => {
      const transaction = await web3.eth.getTransactionReceipt(hash);
      if (transaction) {
        clearInterval(interval);
        resolve(transaction);
      }
    }, 1000);
  });
};

export { getEthProps, checkTransaction };