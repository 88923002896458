import React from 'react';
import Page from '../components/Page';
import ProductList from '../components/ProductList';

import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';



const HomepageEdits = () => {

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <Page title={'Homepage edits'}>
            <Container maxWidth="x1">
                <Typography variant="h4" gutterBottom>
                    Homepage Edits
                </Typography>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label="Product list" {...a11yProps(0)} />
                            {/* <Tab label="Tab 2" {...a11yProps(1)} /> */}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <ProductList />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Nothing here yet
                    </TabPanel>
                </Box>
            </Container>
        </Page>
    );
};

export default HomepageEdits;
