import {Stack} from "@mui/material";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FormProvider, RHFTextField} from "../../../components/hook-form";

const FeeForm = ({defaultValues, onSubmit}) => {

    const FeeSchema = Yup.object().shape({
        fee: Yup.number().required('Fee is required'),
    });

    const methods = useForm({
        resolver: yupResolver(FeeSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ my: 2 }}>
                <RHFTextField name="fee" label="Fee" placeholder="Enter fee" />
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                Edit fee
            </LoadingButton>
        </FormProvider>
    )
}

export default FeeForm;