import {useEffect, useState} from "react";
import { Link as RouterLink, Navigate } from 'react-router-dom';
import Web3 from "web3";
// @mui
import {
  Container,
  Typography,
  Stack,
  CircularProgress
} from '@mui/material';

// components
import { useAuth } from '../contexts/AuthProvider';
import Page from '../components/Page';
import {getFeeChangeSignature, updateFee, getFee} from "../api/fee.js";
import {FeeForm} from "../sections/Settings/FeeForm";
import { getEthProps, checkTransaction } from '../utils/web3';


export default function Settings() {

  const { user } = useAuth();

  if(!user) return <Navigate to="/login" />;

  const [defaultValues, setDefaultValues] = useState({
    fee: 1,
  });

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    getFee()
        .then((data) => setDefaultValues({...defaultValues, fee: data}))
        .catch((err) => console.log(err))
        .finally(() => setLoader(false));
  }, []);

  const onSubmit = async ({fee}) => {
    try {
      const {provider, address} = await getEthProps();
      const signature = await getFeeChangeSignature(fee);
      const hash = await provider.request({
        method: 'eth_sendTransaction',
        params: [{ from: address[0], ...signature.data.methodRequest }],
      });

      const web3 = new Web3(provider);
      await checkTransaction(web3, hash);

      await updateFee(fee);
    } catch(e) {
      console.error(e);
    }
  };


  return (
    <Page title="Settings">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Settings
          </Typography>
        </Stack>

        {loader ? (
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="center" mb={5} style={{height: '100vh'}}>
                <CircularProgress />
              </Stack>
            </Container>
        ) : (
            <FeeForm defaultValues={defaultValues} onSubmit={(values) => onSubmit(values)} />
        )}
      </Container>
    </Page>
  );
}
