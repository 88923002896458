import { useState } from 'react';
import { Navigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, CircularProgress, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import { useAuth } from '../contexts/AuthProvider';
import { ConfirmLoginForm } from "../sections/auth/confirm-login";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { user } = useAuth();

  const [email, setEmail] = useState(null);
  const [loader, setLoader] = useState(false);

  const clearEmail = () => setEmail(null);

  if (user) return <Navigate to='/dashboard/orders' />;

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />

          {/* {smUp && ( */}
          {/*  <Typography variant="body2" sx={{ mt: { md: -2 } }}> */}
          {/*    Don’t have an account? {''} */}
          {/*    <Link variant="subtitle2" component={RouterLink} to="/register"> */}
          {/*      Get started */}
          {/*    </Link> */}
          {/*  </Typography> */}
          {/* )} */}
        </HeaderStyle>

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>

            {
              loader ? (
                  <Container>
                    <Stack direction="row" alignItems="center" justifyContent="center" mb={5} style={{height: '20vh'}}>
                      <CircularProgress />
                    </Stack>
                  </Container>
              ) :
                  email
                      ? <ConfirmLoginForm email={email} clearEmail={clearEmail} setLoader={setLoader}/>
                      : <LoginForm setEmail={setEmail} setLoader={setLoader}/>
            }

            {/* {!smUp && ( */}
            {/*  <Typography variant="body2" align="center" sx={{ mt: 3 }}> */}
            {/*    Don’t have an account?{' '} */}
            {/*    <Link variant="subtitle2" component={RouterLink} to="/register"> */}
            {/*      Get started */}
            {/*    </Link> */}
            {/*  </Typography> */}
            {/* )} */}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
