import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Alert, AlertTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { login } from '../../../api';

// ----------------------------------------------------------------------

export default function LoginForm({ setEmail, setLoader }) {
  const [showPassword, setShowPassword] = useState(false);

  const [hasError, setHasError] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    setLoader(true);
    login(values)
      .then(() => setEmail(values.email))
      .catch((err) => setHasError(true))
      .finally(() =>  setLoader(false));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ my: 2 }}>
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" style={{position: 'absolute', top: '50%', right: 20}}>
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
           style: {
             position: 'relative',
             width: '100%',
             paddingRight: 0
           }
          }}
        />
        {hasError && (
          <Alert severity="error" onClose={() => setHasError(false)}>
            <AlertTitle>Error</AlertTitle>
            Something went wrong, please check your email or password
          </Alert>
        )}
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}> */}
      {/*  <RHFCheckbox name="remember" label="Remember me" /> */}
      {/*  <Link variant="subtitle2" underline="hover"> */}
      {/*    Forgot password? */}
      {/*  </Link> */}
      {/* </Stack> */}

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
    </FormProvider>
  );
}
