import Page from '../../components/Page';

import {
  Button,
  Card,
  Container, Input,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { UserListHead } from '../../sections/@dashboard/user';
import React, { useEffect, useState } from 'react';

import { getProducts } from '../../api';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import { ProductFilter } from '../../constants';
import { toDisplayFormat } from '../../utils/textTransform';
import { formatHash } from 'src/utils/formatHash';
import {ProductHistoryModal} from "./ProductHistoryModal";
import {OffersModal} from "./OffersModal";

const getIcon = (name) => <Iconify icon={name} width={25} height={25} />;

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'offersCount', label: 'Offers', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'assetId', label: 'AssetId', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'publicAddress', label: 'Public Address', alignRight: false },
  { id: 'linkToSteam', label: 'LinkToSteam', alignRight: false },
  { id: 'action', label: '', alignRight: false },
];

const BASE_APP_URL = process.env.REACT_APP_PUBLIC_APP_URL;

const sortByValue = {
  NEW: 'new',
  OLD: 'old',
  MOST_OFFERS: 'most-offers',
}

const Products = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const [orderBy, setOrderBy] = useState('asc');
  const [productsList, setProductsList] = useState([]);
  const [totalProducts, setTotalProducts] = useState(null);
  const [openProductHistory, setOpenProductHistory] = useState(false);
  const [openOffersModal, setOpenOffersModal] = useState(false);
  const [productStatusFilter, setProductStatusFilter] = useState(ProductFilter.LISTED);
  const [sortByFilter, setSortByFilter] = useState(sortByValue.NEW)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [publicAddressFilter, setPublicAddressFilter] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);

  const setSnackBarStatus = (message, isError) => {
    setMessage(message);
    setIsError(isError);
    setShowSnackbar(true);
  };

  const statusOnChange = (e) => {
    setProductStatusFilter(e.target.value);
    setPage(0);
  };

  const sortByOnChange = (e) => {
    setSortByFilter(e.target.value);
    setPage(0);
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const itemsPerPage = +event.target.value;

    setRowsPerPage(itemsPerPage);
    setPage(0);
  };

  const getProductsList = ({ limit, offset, status, sortBy }) => {
    setLoader(true);

    const params = {
      limit: limit || rowsPerPage,
      offset: offset || page * rowsPerPage,
      status: status || productStatusFilter,
      sortBy: sortBy || sortByFilter,
    };

    if (publicAddressFilter) {
      params.publicAddress = publicAddressFilter;
    }

    getProducts(params)
      .then(({ data, total }) => {
        setProductsList(data);
        setTotalProducts(total);
      })
      .catch((err) => setSnackBarStatus(err.message, true))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    getProductsList({});
  }, [page, rowsPerPage, productStatusFilter, publicAddressFilter, sortByFilter]);

  const handleProductHistoryOpen = () => {
    setOpenProductHistory(true);
  };

  const publicAddressOnChange = (e) => {
    setPublicAddressFilter(e.target.value);
    setPage(0);
  };

  return (
    <Page title={'Products'}>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Products
          </Typography>
          <Snackbar
            open={showSnackbar}
            message={message}
            severity={isError && 'error'}
            onClose={() => setShowSnackbar(false)}
            autoHideDuration={3000}
          />
        </Stack>

        <Stack direction="row" mb={5}>
          <Container direction="row" sx={{ ml: 0 }}>
            <Select onChange={sortByOnChange} defaultValue={sortByValue.NEW} sx={{ width: '300px', ml: '25px' }}>
              {Object.values(sortByValue).map((value) => (
                <MenuItem key={value} value={value}>
                  {toDisplayFormat(value)}
                </MenuItem>
              ))}
            </Select>
            <Select onChange={statusOnChange} defaultValue={ProductFilter.LISTED} sx={{ width: '300px', ml: '25px' }}>
              {Object.values(ProductFilter).map((status) => (
                <MenuItem key={status} value={status}>
                  {toDisplayFormat(status)}
                </MenuItem>
              ))}
            </Select>
            <Input onChange={publicAddressOnChange} sx={{ width: '350px', ml: '30px' }} placeholder="Public address" />
          </Container>
        </Stack>

        <Card>
          <TableContainer sx={{ width: '100%' }}>
            <Scrollbar>
              <Table>
                <UserListHead order={orderBy} headLabel={TABLE_HEAD} rowCount={productsList?.length} />
                <TableBody>
                  {productsList?.map((row) => {
                    const linkToSteam = `https://steamcommunity.com${row.linkToSteam}`;
                    const rowLink = '/product/' + (row.alias ? row.alias : row.id);

                    return (
                      <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.id}
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <img src={row.image} />
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.status}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.offersCount}
                        >
                          {row.offersCount}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.name}
                        >
                          {row.status === 'listed' ? <Button href={BASE_APP_URL + rowLink} target='_blank' rel="noreferrer">{row.name}</Button> : row.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.originId}
                        >
                          {row.originId}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.price}
                        >
                          {row.price}
                        </TableCell>
                        <TableCell align="left"
                          onClick={() => navigator.clipboard.writeText(row.user.publicAddress)}
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            textOverflow: 'ellipsis',
                            cursor: 'copy'
                          }}
                          title={row.user.publicAddress}>{formatHash(row.user.publicAddress)}</TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 120,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                            cursor: 'copy',
                          }}
                          title={row.linkToSteam}
                        >
                          <Button href={linkToSteam} target={"_blank"} rel="noreferrer">{row.linkToSteam}</Button>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            width: 130,
                            textOverflow: 'ellipsis',
                          }}
                          title={'action'}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleProductHistoryOpen();
                              setCurrentProduct(row);
                            }}
                          >
                            Show history
                          </Button>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            width: 130,
                            textOverflow: 'ellipsis',
                          }}
                          title={'action'}
                        >
                          <Button
                              disabled={!row.offersCount}
                            variant="contained"
                            onClick={() => {
                              setOpenOffersModal(true);
                              setCurrentProduct(row);
                            }}
                          >
                            Show offers
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {productsList.length === 0 && (
                    <TableRow style={{ height: 250 }}>
                      <TableCell colSpan={6}> No Products Was Found </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
            component="div"
            count={totalProducts || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ProductHistoryModal
          openModal={openProductHistory}
          setOpenModal={setOpenProductHistory}
          currentProduct={currentProduct}
          setCurrentProduct={setCurrentProduct}
          setSnackBarStatus={setSnackBarStatus}
          setLoader={setLoader}
      />
      <OffersModal
          openModal={openOffersModal}
          setOpenModal={setOpenOffersModal}
          currentProduct={currentProduct}
          setCurrentProduct={setCurrentProduct}
          setSnackBarStatus={setSnackBarStatus}
          setLoader={setLoader}
      />
    </Page>
  );
};

export default Products;
