const getSkinImage = (
  groupsImages,
  qualityType: 'high' | 'medium' | 'small' = 'medium',
  aspect: 'left' | 'right' | 'front-left' = 'left',
  withBackground?: boolean
) => {
  const selectedImage = groupsImages.find(
    imageGroup =>
      imageGroup.image.quality === qualityType && imageGroup.image.aspect === aspect && imageGroup.image.withBackground === !!withBackground
  );
  // return needed image quality URL or first URL of the Image that not equal null and has no background
  return (
    selectedImage?.image?.url || groupsImages.find(imageGroup => imageGroup.image.url && !imageGroup.image.withBackground)?.image?.url || ''
  );
};

export { getSkinImage };
