import Page from '../components/Page';

import {
  Button,
  Card,
  Container, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Stack,
  Checkbox,
  Input,
  Box,
} from '@mui/material';
import { UserListHead } from '../sections/@dashboard/user';
import React, { useEffect, useState } from 'react';

import { getEditorChoice, putEditorChoice, getGroup } from '../api';
import Scrollbar from '../components/Scrollbar';
import { getSkinImage } from '../helpers/product';


const TABLE_HEAD = [
  { id: '', label: '', alignRight: false },
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
];

const MODAL_STYLE = {
  textAlign: 'center',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 1050,
  maxHeight: '90vh',
  minHeight: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 10,
  p: 4,
};


const ProductList = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const [editorsChoiseList, setEditorsChoiseList] = useState([]);
  const [editorsChoiseTotalProducts, setEditorsChoiseTotalProducts] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);


  const [groupsList, setGroupsList] = useState([]);
  const [groupsListTotalProducts, setGroupsListTotalProducts] = useState(null);
  const [rowsPerPageGroup, setRowsPerPageGroup] = useState(10);
  const [pageGroup, setPageGroup] = useState(0);

  const [editing, setEditing] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [isAddToEditorsListOn, setIsAddToEditorsListOn] = useState(false);

  const [nameFilter, setNameFilter] = useState(null);

  const setSnackBarStatus = (message, isError) => {
    setMessage(message);
    setIsError(isError);
    setShowSnackbar(true);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangePageGroup = (_, newPage) => {
    setPageGroup(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const itemsPerPage = +event.target.value;

    setRowsPerPage(itemsPerPage);
    setPage(0);
  };

  const handleChangeRowsPerPageGroup = (event) => {
    const itemsPerPage = +event.target.value;

    setRowsPerPageGroup(itemsPerPage);
    setPageGroup(0);
  };

  const getEditorChoiceList = ({ limit, offset }) => {
    setLoader(true);

    const params = {
      limit: limit || rowsPerPage,
      offset: offset || page * rowsPerPage,
    };

    getEditorChoice(params)
      .then(({ data, total }) => {
        setEditorsChoiseList(data);
        setEditorsChoiseTotalProducts(total);
      })
      .catch((err) => setSnackBarStatus(err.message, true))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    if (isAddToEditorsListOn) {
      getGroupList({});
    } else {
      getEditorChoiceList({});
    }
  }, [isAddToEditorsListOn, nameFilter, page, rowsPerPage, pageGroup, rowsPerPageGroup]);

  const handleEditClick = () => {
    setEditing(prevValue => !prevValue);
  };

  const handleCheckboxChange = (id, event) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[id] = event.target.checked;
    setCheckboxes(newCheckboxes);
  };

  const handleSaveClick = async () => {
   
    const list = isAddToEditorsListOn ? groupsList : editorsChoiseList;

    const putRequestData = list.reduce((acc, item, index) => {
        if (checkboxes[item.id] !== undefined) {
          acc.push({
            groupId: item.id,
            isEditorChoice: checkboxes[item.id]
          });
        }
        return acc;
      }, [])

    if (putRequestData.length <= 0) {
      setEditing(false);
      return
    }

    await putEditorChoice(putRequestData)
      .then(({ data }) => {
      })
      .catch((err) => setSnackBarStatus(err.message, true))
      .finally(() => setLoader(false));

    await getEditorChoiceList({});

    if (isAddToEditorsListOn) {
      setIsAddToEditorsListOn(false)
    } else {
      setEditing(false);
    }
  };

  const handleAddClick = () => {
    setNameFilter('')
    setIsAddToEditorsListOn((prevValue) => !prevValue);
  }

  const getGroupList = ({ limit, offset }) => {
    setLoader(true);

    const params = {
      limit: limit || rowsPerPageGroup,
      offset: offset || pageGroup * rowsPerPageGroup,
      appId: 1,
      sortBy: 'price-highest',
      ...(nameFilter && { search: nameFilter }),
    };

    getGroup(params)
      .then(({ data, total }) => {
        setGroupsList(data);
        setGroupsListTotalProducts(total);
      })
      .catch((err) => setSnackBarStatus(err.message, true))
      .finally(() => setLoader(false));
  };

  const nameFilterOnChange = (e) => {
    setNameFilter(e.target.value);
    setPageGroup(0);
  };

  return (
      <Page title={'Homepage Edits'}>
          <Container maxWidth="xl">
          {isAddToEditorsListOn ? (
            <>
            <Container maxWidth="xl">
              <Stack direction="row" mb={5} sx={{ justifyContent: 'flex-end' }}>
                <Box mr={3}>
                  <Input onChange={nameFilterOnChange} sx={{ width: '280px', ml: '30px' }} placeholder="Name" />
                </Box>
                <Box>
                  <Button onClick={handleAddClick} disabled={editing}>{isAddToEditorsListOn ? 'Cancel' : 'Add'}</Button>
                  <Button onClick={handleEditClick} disabled={isAddToEditorsListOn}>{editing ? 'Cancel' : 'Edit'}</Button>
                  <Button onClick={handleSaveClick} disabled={!editing && !isAddToEditorsListOn}>Save</Button>
                </Box>
              </Stack>
            </Container>
            <Card>
            <TableContainer sx={{ width: '100%' }}>
            <Scrollbar>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={groupsList?.length} />
                <TableBody>
                  {groupsList?.map((row) => {
                    const rowImage = getSkinImage(row?.groupsImages) || row?.contentImageUrl || '/';
                    return (
                      <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
                        <TableCell>
                            <Checkbox
                              checked={!!checkboxes[row.id] && checkboxes[row.id]}
                              onChange={event => handleCheckboxChange(row.id, event)}
                            />
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.id}
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <img src={rowImage} width='150px'/>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.name}
                        >
                          {row.name}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {groupsList.length === 0 && (
                    <TableRow style={{ height: 250 }}>
                      <TableCell colSpan={6}> No Products Was Found </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
            component="div"
            count={groupsListTotalProducts || 0}
            rowsPerPage={rowsPerPageGroup}
            page={pageGroup}
            onPageChange={handleChangePageGroup}
            onRowsPerPageChange={handleChangeRowsPerPageGroup}
          />
        </Card>
            </>
          ) : (
            <>
            <Container maxWidth="xl">
              <Stack direction="row" mb={5} sx={{ justifyContent: 'flex-end' }}>
                <Box>
                  <Button onClick={handleAddClick} disabled={editing}>{isAddToEditorsListOn ? 'Cancel' : 'Add'}</Button>
                  <Button onClick={handleEditClick} disabled={isAddToEditorsListOn}>{editing ? 'Cancel' : 'Edit'}</Button>
                  <Button onClick={handleSaveClick} disabled={!editing && !isAddToEditorsListOn}>Save</Button>
                </Box>
              </Stack>
            </Container>
            <Card>
            <TableContainer sx={{ width: '100%' }}>
            <Scrollbar>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} rowCount={editorsChoiseList?.length} />
                <TableBody>
                  {editorsChoiseList?.map((row) => {
                    const rowImage = getSkinImage(row?.groupsImages) || row?.contentImageUrl || '/';
                    return (
                      <TableRow hover key={row.id} tabIndex={-1} role="checkbox">
                        <TableCell>
                          {editing && (
                            <Checkbox
                              checked={checkboxes[row.id] !== undefined ?  checkboxes[row.id] : true}
                              onChange={event => handleCheckboxChange(row.id, event)}
                              disabled={!editing}
                            />
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.id}
                        >
                          {row.id}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                        >
                          <img src={rowImage} width='150px'/>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            overflow: 'hidden',
                            maxWidth: 140,
                            minWidth: 100,
                            textOverflow: 'ellipsis',
                          }}
                          title={row.name}
                        >
                          {row.name}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {editorsChoiseList.length === 0 && (
                    <TableRow style={{ height: 250 }}>
                      <TableCell colSpan={6}> No Products Was Found </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
            component="div"
            count={editorsChoiseTotalProducts || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
            </>
        )}
      </Container>
    </Page>
  );
};

export default ProductList;
